document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  let module = document.querySelector(".user-board-list-share-icon");
  if (module === null) {
    return;
  }

  if (!navigator.canShare) {
    return;
  }

  module.style.display = "flex";

  let iconModule = document.querySelector(
    ".user-board-list-share-icon .icon-with-bottom-text-module"
  );
  if (iconModule === null) {
    return;
  }

  let titleText = document.querySelector("title").innerText;
  let descriptionText = document.querySelector("[name=description][content]")
    .content;
  let urlText = location.href;

  const shareData = {
    title: titleText,
    text: descriptionText,
    url: urlText
  };

  iconModule.addEventListener("click", async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log(err);
    }
  });
});
