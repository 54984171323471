document.addEventListener("DOMContentLoaded", function(event) {
  const element = document.querySelectorAll(".sns-share-module");
  if (element.length == 0) {
    return;
  }

  let modules = document.querySelectorAll(".sns-share-module");

  async function copyToClipboard(text, button) {
    try {
      await navigator.clipboard.writeText(text);
      button.classList.add("copied");
      const copyText = button.getElementsByClassName("text")[0];
      copyText.innerText = "コピーしました";
    } catch (error) {
      button.innerText = "失敗しました";
    }
  }

  modules.forEach(element => {
    let urlCopyButton = element.getElementsByClassName("url-copy-button")[0];
    let url = urlCopyButton.dataset.url;
    urlCopyButton.addEventListener("click", function() {
      copyToClipboard(url, urlCopyButton);
    });
  });
});
