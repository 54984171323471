document.addEventListener("DOMContentLoaded", function(event) {
  const swiperClass = document.querySelectorAll(".sub-navigation-module");
  if (swiperClass.length == 0) {
    return;
  }

  const subNavigationIndex = document
    .getElementsByName("manba-meta-sub-navigation-index")
    .item(0).content;
  const target = document.getElementById("sub-navigation");
  target.scrollLeft += 75 * parseInt(subNavigationIndex, 10);
});
