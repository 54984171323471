import pubsubStore from "../pubsub_store";
import quicklinkHelperInstance from "../quicklink_helper";

document.addEventListener("DOMContentLoaded", function (event) {
    let rootElement = document.querySelector('.infinite-scroll-mode-component');
    if (rootElement == null) {
        return;
    }

    const loadOnScrollValue = rootElement.dataset.loadOnScrollValue.toLowerCase() === 'true';
    const loadCountValue = parseInt(rootElement.dataset.loadCountValue, 10)

    let viewMoreButton = rootElement.querySelector('.infinite-scroll-readmore-module');
    const element = rootElement.querySelector('.infinite-scroll-mode-main-contents');

    let infScroll = new InfiniteScroll(element, {
        path: 'nav.pagination a[rel=next]',
        append: '.infinite-scroll-mode-main-contents',
        history: 'replace',
        status: '.page-load-status',
        scrollThreshold: 1200,
        button: viewMoreButton,
    });

    infScroll.on('append', function (body, path, items, response) {
        let targetElement = document.querySelector('.infinite-scroll-mode-component');

        const quicklinkEnabledElement = document.querySelector('meta[name="quicklink_enabled"]')
        let quicklinkEnabled = false

        if (quicklinkEnabledElement !== null) {
            quicklinkEnabled = quicklinkEnabledElement.content === 'true' ? true : false
        }

        if (quicklinkEnabled == true) {
            // let links = targetElement.querySelectorAll('a');
            // links.forEach(link => {
            //    quicklinkHelperInstance.prefetch(link.href);
            // });

            const nextPageElement = body.head.querySelector('link[rel="next"]')
            if (nextPageElement != null) {
                quicklinkHelperInstance.prefetch(nextPageElement.href);
            }
            const prevPageElement = body.head.querySelector('link[rel="prev"]')
            if (prevPageElement != null) {
                quicklinkHelperInstance.prefetch(prevPageElement.href);
            }
        }

        gtag('config', 'G-ZVDPNNBLJT', {
            'page_location': path,
            'page_referrer': document.referrer,
        });

        pubsubStore.publish("infiniteScrollPageAppend", items[0]);
    });

    infScroll.on('load', onPageLoad);
    infScroll.on('last', onLastPageLoad);
    let isLastPage = false;
    function onPageLoad() {
        if (loadOnScrollValue == true) {
            infScroll.options.loadOnScroll = true;
            return;
        }

        if (infScroll.loadCount > 1 && infScroll.loadCount % loadCountValue == 0 && isLastPage == false) {
            infScroll.options.loadOnScroll = false;
            viewMoreButton.style.display = 'flex';
        } else {
            infScroll.options.loadOnScroll = true;
            viewMoreButton.style.display = 'none';
        }
    }

    function onLastPageLoad(body, path) {
        isLastPage = true;
    }
});
