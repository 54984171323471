import Api from "../../Api";
import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  const modules = document.querySelectorAll(".want-to-recalls-follow-button-module");
  if (modules.length == 0) {
    return;

  }

  let updateFollowButtonState = element => {
    let topicId = element.dataset.want - to - recallsId;

    Api.want - to - recallsFollowing(topicId).then(json => {
      let followActionButton = element.querySelector(
        ".login-action-button.follow-action-button"
      );
      let actionButton = followActionButton.querySelector(".action-button");
      let notLoginActionButton = element.querySelector(
        ".not-login-action-button.follow-action-button"
      );
      let actionButtonText = actionButton.querySelector(".text");

      notLoginActionButton.style.display = "none";
      followActionButton.style.display = "block";

      if (json.following) {
        actionButtonText.innerText = "フォロー中";
        actionButton.classList.add("selected");
      }
    });
  };

  let initFollowButtonState = () => {
    modules.forEach(element => {
      let topicId = element.dataset.topicId;
      const actionFollowButtonElement = element.querySelector(".follow-action-button");
      const actionButtonElement = element.querySelector(".action-button");
      const actionTextElement = element.querySelector(".text");

      Api.topicFollowing(topicId).then(json => {
        if (json.following) {
          actionButtonElement.classList.add("selected");
          actionTextElement.innerText = "フォロー中";
        }
      });

      actionFollowButtonElement.addEventListener("click", function () {
        if (loginStateStore.isLoggedIn() == false) {
          const uri = encodeURI(location.pathname + location.search + location.hash);
          location.href = '/login?rt=' + uri;
          return;
        } else {
          if (actionButtonElement.classList.contains("selected") == true) {
            Api.removeTopicFollow(topicId).then(json => { });
            actionButtonElement.classList.remove("selected");
            actionTextElement.innerText = "フォローする";
          } else {
            Api.addTopicFollow(topicId).then(json => { });
            actionButtonElement.classList.add("selected");
            actionTextElement.innerText = "フォロー中";
          }
        }
      });
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initFollowButtonState();
  });
});
