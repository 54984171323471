document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  const element = document.querySelectorAll(".users-settings-profile-module");
  if (element.length == 0) {
    return;
  }

  const openFileDialog = () => {
    let fileSelector = document.querySelector("input.file-field");
    let iconProfileImage = document.querySelector(
      ".user-settings-profile-icon-image img"
    );

    fileSelector.addEventListener("change", function(e) {
      let files = e.target.files;

      for (var i = 0, file; (file = files[i]); i++) {
        if (!file.type.match("image.*")) {
          continue;
        }

        var reader = new FileReader();

        reader.onload = (function(f) {
          return function(e) {
            try {
              iconProfileImage.src = e.target.result;
            } catch (e) {}
          };
        })(file);

        reader.readAsDataURL(file);

        return;
      }
    });

    fileSelector.click();
  };

  let imageSelector = document.querySelector(
    ".user-settings-profile-icon-file"
  );
  let imageSelectorImage = document.querySelector(
    ".user-settings-profile-icon-image"
  );

  imageSelector.addEventListener("click", function(event) {
    openFileDialog();
  });

  imageSelectorImage.addEventListener("click", function(event) {
    openFileDialog();
  });

  let userSettingPublicCheckBox = document.querySelector(
    "input[type='checkbox'][name='user_setting[public]']#user_setting_public "
  );

  const updateUserSettingPublic = () => {
    let userSettingPublicContent = document.querySelector(
      ".user-settings-public"
    );
    let userSettingPublicPageView = document.querySelector(
      ".user-settings-public-page-preview"
    );

    if (userSettingPublicCheckBox.checked == true) {
      userSettingPublicContent.classList.remove("private");
      userSettingPublicPageView.style.display = "block";
    } else {
      userSettingPublicContent.classList.add("private");
      userSettingPublicPageView.style.display = "none";
    }
  };

  userSettingPublicCheckBox.addEventListener("change", () => {
    updateUserSettingPublic();
  });
  updateUserSettingPublic();
});
