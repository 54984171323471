import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  let element = document.getElementById("js-menu-smartphone-admin-link");

  if (element == null) {
    return;
  }

  let initLoginState = () => {
    let targetElement = document.getElementById(
      "js-menu-smartphone-admin-link"
    );

    if (loginStateStore.isAdmin()) {
      let adminLinkElement = document.querySelector(
        ".smartphone-header-admin-link a"
      );
      adminLinkElement.href = loginStateStore.getAdminPagePath();
      adminLinkElement.setAttribute("rel", 'noprefetch' );

      targetElement.style.display = "block";
    } else {
      targetElement.remove();
    }
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initLoginState();
  });
});
