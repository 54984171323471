document.addEventListener("DOMContentLoaded", function (event) {

  const twitterTweetElemnets = document.querySelectorAll('.twitter-tweet');

  if (twitterTweetElemnets.length === 0) {
    return;
  }

  // Twitter Tweet
  twitterTweetElemnets.forEach(function (node) {
    let parentNode = document.createElement('div');
    parentNode.className = 'embed-twitter';
    node.parentNode.insertBefore(parentNode, node);
    parentNode.appendChild(node);
    node.classList.replace('twitter-tweet', 'lazy-tweet');
  });

  const lazyTwitterTweetElemnets = document.getElementsByClassName('lazy-tweet');

  if (lazyTwitterTweetElemnets.length !== 0) {
    Defer.js('https://platform.twitter.com/widgets.js', 'twitter-js', 700, function () {
      Defer.dom('.lazy-tweet', 0, null, function (node) {
        node.className = 'twitter-tweet';
        twttr.widgets.load(node.parentNode);
      }, { rootMargin: "150% 0%" });
    });
  }
});

