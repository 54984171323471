document.addEventListener("DOMContentLoaded", function (event) {
  let isExist = document.getElementById("js-layout-topics");

  if (isExist == null) {
    return;
  }

  let isNowSmarthone = undefined;

  function updateResize() {
    let smartphoneBoardInfo = document.getElementById("smartphone-board-info");
    let desktopBoardInfo = document.getElementById("desktop-board-info");
    let boardInfo = document.querySelector(".board-info-topic-components");

    if (
      smartphoneBoardInfo == null ||
      desktopBoardInfo == null ||
      boardInfo == null
    ) {
      return;
    }

    let isSmartPhone = app.isSmartPhone();

    if (isNowSmarthone == isSmartPhone) {
      return;
    }

    if (isSmartPhone) {
      smartphoneBoardInfo.appendChild(boardInfo);
    } else {
      desktopBoardInfo.appendChild(boardInfo);
    }
    isNowSmarthone = isSmartPhone;
  }

  window.addEventListener(
    "resize",
    function () {
      updateResize();
    },
    { passive: true }
  );

  updateResize();
});
