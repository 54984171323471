import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  let element = document.getElementById("js-menu-smartphone-login-icon");

  if (element == null) {
    return;
  }

  let initLoginState = () => {
    let targetElement = document.getElementById(
      "js-menu-smartphone-login-icon"
    );
    let loginElement = targetElement.querySelector(".menu-login-icon .logined");
    let notLoginElement = targetElement.querySelector(
      ".menu-login-icon .not-login"
    );

    if (loginStateStore.isUser()) {
      notLoginElement.style.display = "none";
      loginElement.style.display = "block";

      let loginLinkElement = loginElement.querySelector("a");
      loginLinkElement.href = "/me";

      let loginImgElement = loginElement.querySelector("img");
      loginImgElement.src = loginStateStore.getUserIconPath();

      if (loginStateStore.getNotificationCount() >= 1) {
        let notificationElement = loginElement.querySelector(
          ".notifications-count"
        );
        notificationElement.style.display = "block";
        notificationElement.innerText = loginStateStore.getNotificationCount();
      }
    } else {
      notLoginElement.style.display = "block";
    }
  };
  pubsubStore.subscribe("userLoginCompleted", args => {
    initLoginState();
  });
});
