import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";
  let module = document.querySelector(".boards-floating-menu-module");

  if (module == null) {
    return;
  }

  let floatingMenuButtonElement = module.querySelector(".floating-menu-button");
  let floatingMenuDialogElement = module.querySelector(".floating-menu-dialog");

  floatingMenuDialogElement.addEventListener('click', (event) => {
    if (event.target.closest('.floating-menu-dialog-contents') === null) {
      floatingMenuDialogElement.close();
    }
  });

  floatingMenuButtonElement.addEventListener('click', (event) => {
    floatingMenuDialogElement.showModal();

    const focusedElement = document.activeElement;

    if (focusedElement !== null) {
      focusedElement.blur();
    }
  });

  const scrollToTopButtonElement = module.querySelector(".floating-menu-item-scroll-to-top");
  scrollToTopButtonElement.addEventListener('click', (event) => {
    app.scrollToHash("#js-scroll-to-top")
    floatingMenuDialogElement.close();
  });

  const scrollToMemoElement = module.querySelector(".floating-menu-item-scroll-to-memo");
  scrollToMemoElement.addEventListener('click', (event) => {
    floatingMenuDialogElement.close();

    if (loginStateStore.isLoggedIn()) {
      const dialogElement = document.querySelector(".board-new-reading-note-dialog");
      dialogElement.showModal();
    } else {
      const uri = encodeURI(location.pathname + location.search + location.hash);
      location.href = '/login?rt=' + uri;
    }

  });

  const scrollToFavoriteRatingElement = module.querySelector(".floating-menu-item-scroll-to-favorite-rating");
  scrollToFavoriteRatingElement.addEventListener('click', (event) => {
    app.scrollToHash("#js-scroll-to-favorite-rating")
    floatingMenuDialogElement.close();

  });

  const scrollToReadStatusElement = module.querySelector(".floating-menu-item-scroll-to-read-status");
  scrollToReadStatusElement.addEventListener('click', (event) => {
    app.scrollToHash("#js-scroll-to-read-status")
    floatingMenuDialogElement.close();
  });

  let calcFloatingPositon = element => {
    let overlayModuleElement = document.querySelector(".pr-footer-overlay-module");
    if (overlayModuleElement == null) {
      return;
    }

    element.classList.add("overlay");

  }
  pubsubStore.subscribe("userLoginCompleted", args => {
    calcFloatingPositon(module)
    module.style.display = "block";
  });


});
