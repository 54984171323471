import algoliasearchApiInstance from "./api";

document.addEventListener("DOMContentLoaded", function (event) {
  function enableAutoComplete(searchInput) {
    const { autocomplete, getAlgoliaResults } = window[
      "@algolia/autocomplete-js"
    ];
    const searchClient = algoliasearchApiInstance.getClient();
    let indexName = algoliasearchApiInstance.getTopicIndexName();

    const autocompleteSearch = autocomplete({
      container: searchInput,
      detachedMediaQuery: "none",
      placeholder: "キーワードで絞り込み",
      autoFocus: true,
      id: "free-space-autocomplete",
      getSources() {
        return [
          {
            getItems({ query }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: indexName,
                    query,
                    params: {
                      filters: "is_free_space_suggestable: true",
                      hitsPerPage: 5
                    }
                  }
                ]
              });
            },
            templates: {
              item({ item, components, html }) {
                return html`
                  <div>
                    ${components.Highlight({
                  hit: item,
                  attribute: "title",
                  tagName: "mark"
                })}
                  </div>
                `;
              }
            },
            onSelect({ insights, insightsEvents, item }) {
              location.href = item.redirect_url;
            }
          }
        ];
      }
    });

    let buttonElement = document.querySelector("#free-space-search-button");
    let inputElement = document.querySelector("#free-space-autocomplete-input");
    buttonElement.addEventListener("click", function (event) {
      let value = inputElement.value;
      if (value !== "") {
        event.preventDefault();
        let redirectUrl = `/search/free_spaces?q=${value}`;
        location.href = redirectUrl;
      }
    });
  }

  function enableEnterSearch() {
    let targetNameId = "#free-space-autocomplete-input";
    let element = document.querySelector(targetNameId);
    if (element === null) {
      return;
    }

    element.addEventListener("keypress", function (event) {
      let value = element.value;
      if (value !== "" && event.key === "Enter") {
        event.preventDefault();
        let redirectUrl = `/search/free_spaces?q=${element.value}`;
        location.href = redirectUrl;
      }
    });
  }

  const freeSpaceSearchNameId = "#free-space-search";
  let element = document.querySelector(freeSpaceSearchNameId);
  if (element === null) {
    return;
  }

  enableAutoComplete(freeSpaceSearchNameId);
  enableEnterSearch();
});
