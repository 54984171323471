document.addEventListener("DOMContentLoaded", function (event) {
	"use strict";

	let modules = document.querySelectorAll(".year-month-select-box-module");

	if (modules.length === 0) {
		return;
	}

	document.getElementById('year-month-select-box-links').addEventListener('change', function () {
		window.location.href = this.value || '';
	});
});
