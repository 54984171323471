document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  return;

  let module = document.querySelector(".user-board-lists-thanks-share");
  if (module === null) {
    return;
  }

  let loadingImageElement = module.querySelector(".share-image .loading-image");
  let popImageElement = module.querySelector(".share-image .pop-image");

  let popImage = popImageElement.querySelector("img");
  popImage.addEventListener("load", e => {
    loadingImageElement.style.display = "none";
    popImageElement.style.display = "block";
  });
});
