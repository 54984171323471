
import pubsubStore from "./pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  const loadScript = (scriptUrl, scriptId) => {
    if (!document.querySelector(`#${scriptId}`)) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.id = scriptId;
      document.body.appendChild(script);
    }
  };

  pubsubStore.subscribe("renderAsyncLoad", eventContainer => {
    loadScript("https://accounts.google.com/gsi/client", "google-one-tap");
  });
});

