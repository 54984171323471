import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  let modules = document.querySelectorAll(".user-board-list-actions");
  if (modules.length == 0) {
    return;
  }

  let initState = () => {
    let key = loginStateStore.getKey();

    modules.forEach(element => {
      let targetKey = element.dataset.userKey;
      if (key === targetKey) {
        element.style.display = "block";
      } else {
        element.remove();
      }
    });
  };
  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });
});
