document.addEventListener("DOMContentLoaded", function(event) {
  if (!app.inLayout("application-affiliates")) {
    return;
  }
  let isExist = document.getElementById("js-vc-link-switch");

  if (isExist == null) {
    return;
  }

  let count = 0;

  const intervalId = setInterval(() => {
    let affiliateUrl = document
      .getElementById("affiliate_url")
      .children[0].getAttribute("href");
    // http://dalr.valuecommerce.com/dck/5cf5181e8a?pid=885844570&sid=3444601&aid=2698580&mid=3157520

    if (affiliateUrl.includes("valuecommerce.com")) {
      clearInterval(intervalId);
      location.href = affiliateUrl;
    }

    if (count > 5) {
      clearInterval(intervalId);
    }
    count++;
  }, 500);
});
