import Api from "../../Api";
import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  const modules = document.querySelectorAll(".board-reread-button-module");
  if (modules.length == 0) {
    return;
  }

  let updateRereadButtonState = element => {
    let boardId = element.dataset.boardId;
    let actionButtonElement = element.querySelector(".action-button");
    let actionButtonElementText = element.querySelector(".text");

    Api.boardRereading(boardId).then(json => {
      if (json.rereading) {
        actionButtonElementText.innerText = "また読みたい";
        actionButtonElement.classList.add("selected");
      }
    });
  };

  let initRereadButtonState = () => {
    modules.forEach(element => {
      updateRereadButtonState(element);

      let boardId = element.dataset.boardId;
      let actionButtonElement = element.querySelector(".action-button");
      let actionButtonElementText = element.querySelector(".text");

      if (loginStateStore.isLoggedIn() === false) {
        actionButtonElement.addEventListener("click", function () {
          const uri = encodeURI(location.pathname + location.search + location.hash);
          location.href = '/login?rt=' + uri;
        });
        return;
      }

      actionButtonElement.addEventListener("click", function () {
        if (actionButtonElement.classList.contains("selected") == true) {
          Api.removeBoardReread(boardId).then(json => { });
          actionButtonElement.classList.remove("selected");
          actionButtonElementText.innerText = "また読みたい";
        } else {
          Api.addBoardReread(boardId).then(json => { });
          actionButtonElement.classList.add("selected");
          actionButtonElementText.innerText = "また読みたい";
        }
      });
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initRereadButtonState();
  });
});
