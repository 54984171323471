import Api from "../../Api";

document.addEventListener("DOMContentLoaded", function (event) {
  let element = document.querySelector("#theme-view-counter");

  if (element == null) {
    return;
  }

  let themeId = element.dataset.themeId;
  Api.themeViewCounter(themeId).then(json => { });
});
