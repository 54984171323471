import Api from "../../Api";

document.addEventListener("DOMContentLoaded", function(event) {
  const element = document.querySelectorAll(".manba-magazines-clap-module");
  if (element.length == 0) {
    return;
  }

  let updateClapButtonState = element => {
    let clapButton = element.getElementsByClassName("clap-button")[0];
    let aritcleID = element.dataset.articleId;

    clapButton.classList.add("claped");
    const clapText = clapButton.getElementsByClassName("text")[0];
    clapText.innerText = "✔︎ 拍手しました";

    /*
        Api.authorFollowing(authorId).then(json => {
            followButton.style.display = 'block';
            if (json.following) {
                followButton.innerText = "フォローやめる";
                followButton.classList.add('selected')
            }　else {
                followButton.innerText = "フォローする";
                followButton.classList.remove('selected')
            }
        })
        */
  };

  let modules = document.querySelectorAll(".manba-magazines-clap-module");

  modules.forEach(element => {
    let clapButton = element.getElementsByClassName("clap-button")[0];

    clapButton.addEventListener("click", function() {
      updateClapButtonState(element);
    });
  });
});
