document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  const boardMetadataSummaryModules = document.querySelectorAll(
    ".board-metadata-summary-module"
  );
  if (boardMetadataSummaryModules.length == 0) {
    return;
  }

  const summaryReadmoreModules = document.querySelectorAll(".summary-readmore");
  if (summaryReadmoreModules.length == 0) {
    return;
  }

  boardMetadataSummaryModules.forEach(element => {
    let summary = element.getElementsByClassName("summary")[0];
    let readMore = element.getElementsByClassName("summary-readmore")[0];

    readMore.addEventListener("click", function() {
      summary.style.maxHeight = "none";
      summary.style.overflow = "none";
      readMore.style.display = "none";
    });
  });
});
