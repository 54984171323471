import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";
import csrfTokenStoreInstance from "../csrf_token_store";

document.addEventListener("DOMContentLoaded", function (event) {
  let isExist = document.querySelector(".new-want-to-recall-form");

  if (isExist == null) {
    return;
  }

  let rootElement = () => {
    return document.querySelector(".new-want-to-recall-form");
  };

  let isPost = () => {
    return rootElement().classList.contains("post");
  };

  let isPut = () => {
    return rootElement().classList.contains("put");
  };

  let isAnonymousEnabled = () => {
    return rootElement().querySelector("#topic_anonymous_enabled").checked;
  };

  let anonymousEnabledElement = () => {
    return rootElement().querySelector("#topic_anonymous_enabled");
  };

  let initFormState = () => {
    let postNameElement = rootElement().querySelector(".post-name-input-text");
    let inputElement = rootElement().querySelector("#comment_name");
    let userIconImageElement = rootElement().querySelector(".user-icon img");

    if (loginStateStore.isLoggedIn()) {
      userIconImageElement.src = loginStateStore.getUserIconPath();

      if (isPost()) {
        postNameElement.style.display = "none";
        anonymousEnabledElement().checked = false;
      }

      if (isPut()) {
        if (isAnonymousEnabled()) {
          postNameElement.style.display = "block";
          userIconImageElement.src = loginStateStore.getUserPrivateIconPath();
        } else {
          inputElement.value = "名無し";
          postNameElement.style.display = "none";
        }
      }
    }
  };

  let imageSelectorDialog = document.querySelector("a#image-selector-button");

  imageSelectorDialog.addEventListener("click", function (event) {
    event.preventDefault();

    let fileSelector = document.querySelector("input.image-field");

    fileSelector.addEventListener("change", function (e) {
      let imagePartial = document.getElementsByClassName("image-partial")[0];
      let pasteImage = document.getElementsByClassName("paste-image")[0];

      let files = e.target.files;

      if (files.length == 0) {
        let imageElement = pasteImage.querySelector("img");

        if (imageElement != null) {
          imageElement.remove();
          return;
        }

        return;
      }

      for (var i = 0, file; (file = files[i]); i++) {
        if (!file.type.match("image.*")) {
          continue;
        }

        var reader = new FileReader();

        reader.onload = (function (f) {
          return function (e) {
            try {
              let imageElement = pasteImage.querySelector("img");

              if (imageElement != null) {
                imageElement.remove();
                return;
              }

              var uploagImageElement = document.createElement("img");
              uploagImageElement.className = "upload-image";
              pasteImage.appendChild(uploagImageElement);

              var img = pasteImage.querySelector("img.upload-image");
              img.src = e.target.result;
            } catch (e) { }
          };
        })(file);

        reader.readAsDataURL(file);

        return;
      }
    });

    fileSelector.click();
  });

  anonymousEnabledElement().addEventListener("click", function (event) {
    event.stopPropagation();

    let postNameElement = rootElement().querySelector(
      ".post-name-partial .post-name-input-text"
    );
    let userIconImageElement = rootElement().querySelector(".user-icon img");

    if (isAnonymousEnabled()) {
      postNameElement.style.display = "block";
      userIconImageElement.src = loginStateStore.getUserPrivateIconPath();
    } else {
      postNameElement.style.display = "none";
      userIconImageElement.src = loginStateStore.getUserIconPath();
    }
  });

  let templateMessage =
    "▼いつごろ読みましたか\nYYYY〜YYYY年頃\n\n" +
    "▼なにで読みましたか？（マンガの形式）\n雑誌 / 単行本（紙） / 単行本（電子） / 縦スクロール（フルカラー）\n\n" +
    "▼（電子書籍の場合）どこで読みましたか\nジャンプ＋ / LINEマンガ / comico / ピッコマ / Twitter / その他【　　】\n\n" +
    "▼本屋さんにあるとしたらどこの棚ですか\n少年・少女・青年・女性・BL・その他【　　】\n\n" +
    "▼作品の長さは\nだいたい全【　　】巻くらい / 読切作品（1話完結）\n\n" +
    "▼絵柄を他のマンガ（著者）で例えると\n（例: 「『〇〇の✕✕』に似てる」「〇〇先生っぽい」）\n\n" +
    "▼作品タイトルの印象\n(例: 「「漢字が多い」「『〇〇の✕✕』のようなタイトル」）\n\n" +
    "▼著者の名前の印象\n（例: 「カタカナで外国人のような名前」「原作と作画で分かれていた」）\n\n" +
    "▼作品について覚えていること【必須】\n\n" +
    "💡ヒント\nどんな情報でも手がかりになるので書いてみましょう（例: 兄が持っていた／表紙には青い肌の男の子の顔が大きく描かれていた）\n\n"

  let templateCopySrcElement = document.getElementById("js-templete-copy-src");
  let templateCopyDestElement = document.getElementById("comment_body");

  templateCopySrcElement.addEventListener("click", function (event) {
    event.stopPropagation();

    if (templateCopyDestElement.value !== "") {
      const result = confirm(
        "入力内容を消去して「質問用テンプレート」を表示しますか？"
      );
      if (result === false) {
        return;
      }
    }

    templateCopyDestElement.value = templateMessage;

    let scrollHeight = templateCopyDestElement.scrollHeight;
    templateCopyDestElement.style.height = scrollHeight + "px";
  });

  let postButtonElement = rootElement().querySelector("#post-button");
  postButtonElement.addEventListener("click", function (e) {
    let authenticityTokenElements = rootElement().querySelectorAll(
      "input[name='authenticity_token'][type='hidden']"
    );
    authenticityTokenElements.forEach(element => {
      element.value = csrfTokenStoreInstance.get().csrfToken;
    });
    window.removeEventListener('beforeunload', beforeUnLoadEvent)

  });


  let setResizeForm = () => {
    let commentBodyForm = document.querySelector("#comment_body");
    let clientHeight = commentBodyForm.clientHeight;

    commentBodyForm.addEventListener("input", () => {
      commentBodyForm.style.height = clientHeight + "px";
      let scrollHeight = commentBodyForm.scrollHeight;
      commentBodyForm.style.height = scrollHeight + "px";
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initFormState();
  });

  setResizeForm();

  let beforeUnLoadEvent = (event) => {
    let topicTitleForm = document.querySelector("#topic_title");
    let topicTitle = topicTitleForm.value;

    let commentBodyForm = document.querySelector("#comment_body");
    let commentBody = commentBodyForm.value;

    if (commentBody.length > 0 || topicTitle.length > 0) {
      event.preventDefault();
      event.returnValue = "このページを離れますか？";
      return "このページを離れますか？";
    }
    return;
  };

  window.addEventListener('beforeunload', beforeUnLoadEvent)


});
