import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  let element = document.getElementById("js-menu-desktop-login-icon");

  if (element == null) {
    return;
  }

  let initLoginState = () => {
    let targetElement = document.getElementById("js-menu-desktop-login-icon");
    let loginElement = targetElement.querySelector(".menu-login-icon .logined");
    let notLoginElement = targetElement.querySelector(
      ".menu-login-icon .not-login"
    );

    if (loginStateStore.isUser()) {
      loginElement.style.display = "flex";
      let loginLinkElement = loginElement.querySelector("a");
      loginLinkElement.href = "/me";

      let loginImgElement = loginElement.querySelector("img");
      loginImgElement.src = loginStateStore.getUserIconPath();

      if (loginStateStore.getNotificationCount() >= 1) {
        let notificationElement = loginElement.querySelector(
          ".notifications-count"
        );
        notificationElement.style.display = "block";
        notificationElement.innerText = loginStateStore.getNotificationCount();
      }

      if (loginStateStore.isAdmin()) {
        let adminTextElement = loginElement.querySelector(".admin-text");
        let adminLinkElement = loginElement.querySelector(".admin-text a");
        adminLinkElement.href = loginStateStore.getAdminPagePath();
        adminLinkElement.setAttribute("rel", 'noprefetch' );

        adminTextElement.style.display = "block";
      }
      notLoginElement.remove();
    } else {
      loginElement.remove();
      notLoginElement.style.display = "block";
    }
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initLoginState();
  });
});
