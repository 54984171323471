import loginStateStore from "../../login_state_store";
import Api from "../../Api";
import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
    "use strict";

    let modules = document.querySelectorAll(".light-review-dialog-module");

    if (modules.length == 0) {
        return;
    }

    let updatePublicStatus = (element, publicStatus) => {
        const lightReviewFormElement = element.querySelector(".light-review-form-module");
        const publicStatusInput = lightReviewFormElement.querySelector(".public-status-input");
        const checkedPublicStatus = publicStatus == "private" ? true : false;
        publicStatusInput.checked = checkedPublicStatus;
    }

    let updateFavoriteRatingState = (element, favoriteRating) => {
        const lightReviewFormElement = element.querySelector(".light-review-form-module");
        const favoriteRatingElements = lightReviewFormElement.querySelectorAll(".favorite-rating");

        // 同じだったら0にする
        if (getFavoriteRating(element) == favoriteRating) {
            favoriteRatingElements.forEach(favoriteRatingElement => {
                favoriteRatingElement.classList.remove("selected");
            });
            return;
        }

        favoriteRatingElements.forEach(favoriteRatingElement => {
            const favoriteRatingValue = favoriteRatingElement.dataset.favoriteRating

            if (favoriteRatingValue <= favoriteRating) {
                favoriteRatingElement.classList.add("selected");
            } else {
                favoriteRatingElement.classList.remove("selected");
            }
        });
    }

    let updateFavoriteRating = (element, favoriteRating) => {
        const lightReviewFormElement = element.querySelector(".light-review-form-module");
        const favoriteRatingElements = lightReviewFormElement.querySelectorAll(".favorite-rating");

        favoriteRatingElements.forEach(favoriteRatingElement => {
            const favoriteRatingValue = favoriteRatingElement.dataset.favoriteRating

            if (favoriteRatingValue <= favoriteRating) {
                favoriteRatingElement.classList.add("selected");
            } else {
                favoriteRatingElement.classList.remove("selected");
            }
        });
    }


    let updateReadingNote = (element, memo) => {
        const lightReviewFormElement = element.querySelector(".light-review-form-module");
        let readingNoteTextInputElement = lightReviewFormElement.querySelector(".reading-notes-text-input");
        readingNoteTextInputElement.textContent = memo;
    }

    let initFavoriteRatingAction = element => {
        const boardId = element.dataset.boardId
        const lightReviewFormElement = element.querySelector(".light-review-form-module");
        const favoriteRatingElements = lightReviewFormElement.querySelectorAll(".favorite-rating");

        if (loginStateStore.isLoggedIn() == false) {
            return;
        }

        Api.boardFavoriteRating(boardId).then(json => {
            const favoriteRating = json.favorite_rating;
            const publicStatus = json.public_status;

            updateFavoriteRatingState(element, favoriteRating)
            updatePublicStatus(element, publicStatus)
        });

        favoriteRatingElements.forEach(favoriteRatingElement => {
            favoriteRatingElement.addEventListener("click", function (event) {
                event.stopPropagation();

                const favoriteRating = favoriteRatingElement.dataset.favoriteRating
                updateFavoriteRatingState(element, favoriteRating)

                const favoriteRatingValue = getFavoriteRating(element)
                const publicState = isPublicState(element)

                Api.updateFavoriteRating(boardId, favoriteRatingValue, publicState)

            });
        });
    };

    let updateParams = element => {
        const boardId = element.dataset.boardId;

        Api.boardReadingNote(boardId).then(json => {
            updateReadingNote(element, json.memo)
        });

        Api.boardFavoriteRating(boardId).then(json => {
            const favoriteRating = json.favorite_rating;
            const publicStatus = json.public_status;

            updateFavoriteRating(element, favoriteRating)
            updatePublicStatus(element, publicStatus)
        });
    };

    let initReadingNoteAction = element => {
        const boardId = element.dataset.boardId
        const lightReviewFormElement = element.querySelector(".light-review-form-module");
        let readingNoteTextInputElement = lightReviewFormElement.querySelector(".reading-notes-text-input");

        if (loginStateStore.isLoggedIn() == false) {
            return;
        }

        const baseHeight = 54

        let clientHeight = readingNoteTextInputElement.clientHeight

        Api.boardReadingNote(boardId).then(json => {
            updateReadingNote(element, json.memo)
            readingNoteTextInputElement.style.height = clientHeight + "px";
            let scrollHeight = readingNoteTextInputElement.scrollHeight > baseHeight ? readingNoteTextInputElement.scrollHeight : baseHeight;
            readingNoteTextInputElement.style.height = scrollHeight + "px";
        });

        readingNoteTextInputElement.addEventListener("input", () => {
            event.stopPropagation();
            readingNoteTextInputElement.style.height = clientHeight + "px";
            let scrollHeight = readingNoteTextInputElement.scrollHeight > baseHeight ? readingNoteTextInputElement.scrollHeight : baseHeight;
            readingNoteTextInputElement.style.height = scrollHeight + "px";
        });
    };

    let getFavoriteRating = (element) => {
        const lightReviewFormElement = element.querySelector(".light-review-form-module");
        const favoriteRatingElements = lightReviewFormElement.querySelectorAll(".favorite-rating");
        let favoriteRating = 0

        favoriteRatingElements.forEach(favoriteRatingElement => {
            if (favoriteRatingElement.classList.contains("selected")) {
                favoriteRating++;
            }
        });
        return favoriteRating
    }

    let isPublicState = (element) => {
        const lightReviewFormElement = element.querySelector(".light-review-form-module");
        const publicStateElement = lightReviewFormElement.querySelector(".public-status-input");
        return !publicStateElement.checked;
    }

    let getReadingNoteMemo = (element) => {
        const lightReviewFormElement = element.querySelector(".light-review-form-module");
        const readingNoteMemoElement = lightReviewFormElement.querySelector(".reading-notes-text-input");
        return readingNoteMemoElement.value;
    }

    let postAction = (element) => {
        let boardId = element.dataset.boardId

        const favoriteRating = getFavoriteRating(element)
        const publicState = isPublicState(element)
        const readingNoteMemo = getReadingNoteMemo(element)

        Api.updateFavoriteRating(boardId, favoriteRating, publicState)
        Api.updateReadingNote(boardId, readingNoteMemo)

        pubsubStore.publish("boardStatusChange", null);

    }

    let initPostButton = element => {
        const dialogElement = element.querySelector(".light-review-dialog");
        const completedButtonActionElement = element.querySelector(".completed-button");

        completedButtonActionElement.addEventListener("click", function (event) {
            event.stopPropagation();
            postAction(element);
            dialogElement.close();
        })
    };

    let initCloseButton = element => {
        const dialogElement = element.querySelector(".light-review-dialog");
        const closeButtonActionElement = element.querySelector(".close-button");
        closeButtonActionElement.addEventListener("click", function (event) {
            event.stopPropagation();
            dialogElement.close();
        })
    };

    let initPublicStateButton = element => {
        let boardId = element.dataset.boardId;
        const lightReviewFormElement = element.querySelector(".light-review-form-module");
        const publicStateElement = lightReviewFormElement.querySelector(".public-status-input");
        publicStateElement.addEventListener("click", function (event) {
            event.stopPropagation();

            const publicState = !publicStateElement.checked;

            const favoriteRating = getFavoriteRating(element)
            Api.updateFavoriteRating(boardId, favoriteRating, publicState)

        })
    };

    let initDialog = element => {
        let dialogElement = element.querySelector(".light-review-dialog");

        dialogElement.addEventListener('click', (event) => {
            if (event.target.closest('.light-review-dialog-contents') === null) {
                dialogElement.close();
            }
        });
    };

    let initState = () => {
        modules.forEach(element => {
            initDialog(element);
            initFavoriteRatingAction(element);
            initPublicStateButton(element);
            initReadingNoteAction(element);
            initPostButton(element)
            initCloseButton(element)
        });
    };

    pubsubStore.subscribe("userLoginCompleted", args => {
        initState();
    });

    pubsubStore.subscribe("infiniteScrollPageAppend", appentRootElement => {
        appentRootElement.querySelectorAll(".light-review-dialog-module").forEach(element => {
            initDialog(element);
            initFavoriteRatingAction(element);
            initPublicStateButton(element);
            initReadingNoteAction(element);
            initPostButton(element)
            initCloseButton(element)
        });
    });

    pubsubStore.subscribe("boardStatusChange", args => {
    });
});
