document.addEventListener("DOMContentLoaded", function(event) {
  const swiperClass = document.querySelectorAll(
    ".manba-magazines-pickup-partial.swiper"
  );
  if (swiperClass.length == 0) {
    return;
  }

  const swiper = new Swiper(".manba-magazines-pickup-partial.swiper", {
    // Optional parameters
    loop: true,
    mousewheel: true,
    slidesPerView: 1.5,
    spaceBetween: 8,
    breakpoints: {
      1200: {
        slidesPerView: 2.5,
        spaceBetween: 50
      }
    },
    centeredSlides: true,
    initialSlide: 0,

    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },

    autoplay: {
      delay: 5000
    }
  });
});
