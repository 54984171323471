document.addEventListener("DOMContentLoaded", () => {

  const stickyHeaders = (() => {
    let stickies;
    const fixedSmartphoneHeaderHeight = 40;

    const load = (stickiesSelector) => {
      stickies = document.querySelectorAll(stickiesSelector);
      if (stickies.length > 0) {
        [...stickies].forEach((sticky) => {
          const wrap = document.createElement('div');
          wrap.className = 'followWrap';
          sticky.parentNode.insertBefore(wrap, sticky);
          wrap.appendChild(sticky);

          const stickyRect = sticky.getBoundingClientRect();

          sticky.dataset.originalPosition = parseInt(stickyRect.top + window.scrollY - fixedSmartphoneHeaderHeight, 10);

          sticky.dataset.originalHeight = sticky.offsetHeight;
          wrap.style.height = `${sticky.offsetHeight}px`;
        });

        window.removeEventListener("scroll", whenScrolling);
        window.addEventListener("scroll", whenScrolling);
      }
    };

    const whenScrolling = () => {
      [...stickies].forEach((sticky, i) => {
        const stickyPosition = parseInt(sticky.dataset.originalPosition, 10);
        if (stickyPosition <= window.scrollY) {
          const nextSticky = stickies[i + 1];

          const stickyHeight = parseInt(sticky.dataset.originalHeight, 10);
          const nextStickyPosition = nextSticky ? parseInt(nextSticky.dataset.originalPosition, 10) - stickyHeight : 0;

          sticky.classList.add("fixed");
          if (nextSticky && sticky.getBoundingClientRect().top >= nextStickyPosition) {
            sticky.classList.add("absolute");
            sticky.style.top = `${nextStickyPosition}px`;
          }

          const pageMainElement = document.querySelector(".manba-main-page-container");
          var clientHeight = pageMainElement.clientHeight;

          if (window.scrollY >= clientHeight) {
            sticky.classList.remove("fixed");
          }

        } else {
          const prevSticky = stickies[i - 1];
          sticky.classList.remove("fixed");

          if (prevSticky && window.scrollY <= stickyPosition - parseInt(sticky.dataset.originalHeight, 10)) {
            prevSticky.classList.remove("absolute");
            prevSticky.style.removeProperty("top");
          }
        }
      });
    };

    return {
      load
    };
  })();

  if (app.isSmartPhone()) {
    stickyHeaders.load(".sticky-caption");
  }

});
