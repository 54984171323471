document.addEventListener("DOMContentLoaded", function(event) {
  let isExist = document.getElementById("js-layout-filter-search");

  if (isExist == null) {
    return;
  }

  let isNowSmarthone = undefined;

  function updateResize() {
    let smartphonePosition = document.getElementById("smartphone-position");
    let desktopPosition = document.getElementById("desktop-position");
    let targetElement = document.querySelector(".target-element");

    if (
      smartphonePosition == null ||
      desktopPosition == null ||
      targetElement == null
    ) {
      return;
    }

    let isSmartPhone = app.isSmartPhone();

    if (isNowSmarthone == isSmartPhone) {
      return;
    }

    if (isSmartPhone) {
      smartphonePosition.appendChild(targetElement);
    } else {
      desktopPosition.appendChild(targetElement);
    }
    isNowSmarthone = isSmartPhone;
  }

  window.addEventListener(
    "resize",
    function() {
      updateResize();
    },
    { passive: true }
  );

  updateResize();
});
