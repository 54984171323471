document.addEventListener("DOMContentLoaded", function (event) {
  const targetElement = document.querySelector(".search-result-tabs-module");
  if (targetElement == null) {
    return;
  }

  const selectedTabName = document.getElementsByName("manba-meta-search-result-tab-name").item(0).content;
  const tabElements = targetElement.querySelectorAll(".result-tab")
  const target = targetElement.querySelector(".result-tabs");
  let scrollLeft = 0;

  for (const tabElement of tabElements) {
    const tabName = tabElement.dataset.tabName;

    if (tabName === selectedTabName) {
      break; // 一致したらループを抜ける
    }

    scrollLeft += tabElement.clientWidth;
  }

  target.scrollLeft = scrollLeft;

});



