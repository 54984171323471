document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  const element = document.querySelectorAll(".user-settings-resign");
  if (element.length == 0) {
    return;
  }

  let modules = document.querySelectorAll(".user-settings-resign");

  modules.forEach(element => {
    let resignButton = element.getElementsByClassName(
      "user-settings-resign-button"
    )[0];
    resignButton.addEventListener("click", function() {
      let api_resign = "/api/me/resign";
      const result = confirm("退会してよろしいですか？");
      if (result === true) {
        return fetch(api_resign, {
          method: "DELETE",
          credentials: "same-origin"
        })
          .then(response => {})
          .then(result => {
            location.href = "/";
          });
      }
    });
  });
});
