import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let modules = document.querySelectorAll(".profiles-user-module");
  if (modules.length == 0) {
    return;
  }

  let initSettingState = element => {
    let key = loginStateStore.getKey();
    let loginProviderName = loginStateStore.getLoginProviderName();
    let loginProviderIcon = loginStateStore.getLoginProviderIconPath();
    let hasProfile = loginStateStore.hasProfile();

    let targetKey = element.dataset.userKey;
    let userProfileSettingLinkElement = element.querySelector(
      ".user-profile-setting-link"
    );
    let userProfileLinksElement = element.querySelector(".user-profile-links");
    let loginProvider = element.querySelector(".login-provider");
    let userHasProfileElement = element.querySelector(".user-has-profile");

    if (key === targetKey) {
      if (hasProfile === false) {
        userHasProfileElement.style.display = "block";
      } else {
        userHasProfileElement.remove();
      }

      userProfileLinksElement.style.display = "block";
      loginProvider.style.display = "flex";

      let loginProviderNameElement = loginProvider.querySelector(
        ".login-provider-name"
      );
      loginProviderNameElement.classList.add(loginProviderName.toLowerCase());

      let imgElement = document.createElement("img");
      imgElement.src = loginProviderIcon;
      imgElement.alt = loginProviderName;
      imgElement.width = 24;
      imgElement.height = 24;

      let loginProviderNameLogoElement = loginProviderNameElement.querySelector(
        ".logo"
      );
      loginProviderNameLogoElement.appendChild(imgElement);

      let loginProviderNameTextElement = loginProviderNameElement.querySelector(
        ".text"
      );
      loginProviderNameTextElement.innerText = loginProviderName;
    } else {
      userProfileSettingLinkElement.remove();
      userProfileLinksElement.remove();
      loginProvider.remove();
      userHasProfileElement.remove();
    }
  };

  let initPublicProfileState = element => {
    let key = loginStateStore.getKey();
    let targetKey = element.dataset.userKey;
    let myPageElements = element.querySelectorAll(".my-page");

    myPageElements.forEach(myPageElement => {
      if (key === targetKey) {
        myPageElement.style.display = "block";
      } else {
        myPageElement.remove();
      }
    });
  };
  let initState = () => {
    modules.forEach(element => {
      initSettingState(element);
      initPublicProfileState(element);
    });
  };
  pubsubStore.subscribe("userLoginCompleted", args => {
    if (loginStateStore.isLoggedIn()) {
      initState();
    }
  });
});
