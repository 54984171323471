import Api from "../Api";

class TopicVisitLogger {
  constructor(element) {
    this.element = element;
    this.init(); // 初期化処理
  }

  async init() {
    try {
      const topicId = this.element.dataset.topicId;
      await this.addUserRecentVisitedTopicLogs(topicId);
    } catch (error) {
      console.error("Error adding topic visit log:", error);
    }
  }

  async addUserRecentVisitedTopicLogs(topicId) {
    await Api.addUserRecentVisitedTopicLogs(topicId);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const element = document.querySelector("#topic-visited");
  if (element) {
    new TopicVisitLogger(element);
  }
});
