import pubsubStore from "../../pubsub_store";
import algoliasearchApiInstance from "../../algoliasearch/api";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let isExist = document.querySelector(".user-board-lists-form");

  if (isExist == null) {
    return;
  }

  let rootElement = () => {
    return document.querySelector(".user-board-lists-form");
  };

  let sortableElement = rootElement().querySelector(".user-board-list-items");
  let sortable = Sortable.create(sortableElement, {
    handle: ".thumbnail" // Element is dropped into the list from another list
  });

  const { autocomplete, getAlgoliaResults } = window[
    "@algolia/autocomplete-js"
  ];
  const searchClient = algoliasearchApiInstance.getClient();
  const indexName = algoliasearchApiInstance.getBoardIndexName();

  const autocompleteSearch = autocomplete({
    container: "#user-board-list-search",
    detachedMediaQuery: "none",
    placeholder: "マンガのタイトルで検索",
    id: "user-board-list-search",
    getSources() {
      return [
        {
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: indexName,
                  query,
                  params: {
                    hitsPerPage: 5
                  }
                }
              ]
            });
          },
          templates: {
            item({ item, components, html }) {
              return html`
                <div class="aa-manba-title-item">
                    <img src=${item.suggest_thumbnail_url} alt='${item.title}' />
                    <div>
                      ${components.Highlight({
                hit: item,
                attribute: "title",
                tagName: "mark"
              })}
                    </div>
                  </div>
              `;
            }
          },
          onSelect({
            state,
            event,
            item,
            setIsOpen,
            setQuery,
            query,
            category
          }) {
            let targetElement = initElement(item);
            sortableElement.appendChild(targetElement);

            enabledPostButton();
            if (app.isSmartPhone()) {
              pageToScroll("#user-board-list-search-partial");
            }
            setQuery("");
            setIsOpen(false);
          }
        }
      ];
    }
  });

  let enabledPostButton = () => {
    let itemElements = rootElement().querySelectorAll(
      ".user-board-list-items .user-board-lists-item-module"
    );
    let postButton = rootElement().querySelector(
      ".post-button-partial #post-button"
    );

    if (itemElements.length == 0 || itemElements.length >= 1000) {
      postButton.disabled = true;
    } else {
      postButton.disabled = false;
    }
  };

  let pageToScroll = targetId => {
    const targetElement = document.querySelector(targetId);
    const targetOffsetTop =
      window.pageYOffset + targetElement.getBoundingClientRect().top - 230;

    window.scrollTo({
      top: targetOffsetTop,
      behavior: "smooth"
    });
  };

  let initElement = suggestion => {
    let baseListItem = rootElement().querySelector(
      ".user-board-lists-base-item .user-board-lists-item-module"
    );
    let newListItem = baseListItem.cloneNode(true);

    let imageElement = newListItem.querySelector(".thumbnail img");
    imageElement.src = suggestion.large_thumbnail_url;

    let titleElement = newListItem.querySelector(".title");
    titleElement.innerText = suggestion.title;

    let authorsElement = newListItem.querySelector(".authors");
    authorsElement.innerText = suggestion.author_names_text;

    let boardIdElement = newListItem.querySelector(
      "#user_board_list_items_board_id"
    );
    boardIdElement.value = suggestion.objectID;

    let deleteActionElement = newListItem.querySelector(".delete-action");
    deleteActionElement.addEventListener("click", function (event) {
      newListItem.remove();
      enabledPostButton();
    });

    return newListItem;
  };

  let initForm = () => {
    let itemElements = rootElement().querySelectorAll(
      ".user-board-lists-item-module"
    );
    itemElements.forEach(itemElement => {
      let deleteActionElement = itemElement.querySelector(".delete-action");
      deleteActionElement.addEventListener("click", function (event) {
        itemElement.remove();
        enabledPostButton();
      });
    });
    enabledPostButton();
  };
  pubsubStore.subscribe("userLoginCompleted", args => { });
  initForm();
});
