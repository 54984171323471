import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";
  let modules = document.querySelectorAll(".read-status-plan-to-read-action-button-module");

  let updateActionState = element => {
    let boardId = element.dataset.boardId
    let topicId = element.dataset.topicId

    let planToReadAction = element.querySelector(".read-status-plan-to-read-action-button");

    if (loginStateStore.isLoggedIn() == false) {
      element.style.display = "block";
      return;
    }

    Api.boardReadStatus(boardId).then(json => {
      let readStatus = json.read_status;
      if (readStatus != "plan_to_read" && readStatus != "nothing") {
        element.remove()
        return
      }

      Api.userTopicOwned(topicId).then(json => {
        if (json.owned == true) {
          element.remove()
          return
        }
      });

      element.style.display = "block";

      if (readStatus == "plan_to_read") {
        planToReadAction.classList.add("selected");
      }
    });
  };

  let initAction = element => {
    let boardId = element.dataset.boardId
    let topicId = element.dataset.topicId

    let planToReadAction = element.querySelector(".read-status-plan-to-read-action-button");

    planToReadAction.addEventListener("click", function () {
      if (loginStateStore.isLoggedIn() == false) {
        const uri = encodeURI(location.pathname + location.search + location.hash);
        location.href = '/login?rt=' + uri;
        return;
      }

      if (planToReadAction.classList.contains("selected") == true) {
        planToReadAction.classList.remove("selected");
        Api.updateReadStatusPlanToReadFromTopic(boardId, topicId);
      } else {
        planToReadAction.classList.add("selected");
        Api.updateReadStatusPlanToReadFromTopic(boardId, topicId);
      }
    });
  };

  let initState = () => {
    modules.forEach(element => {
      initAction(element);
      updateActionState(element);
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });
});
