import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";
  let module = document.querySelector(".topics-floating-menu-module");

  if (module == null) {
    return;
  }

  let floatingMenuButtonElement = module.querySelector(".floating-menu-button");
  let floatingMenuDialogElement = module.querySelector(".floating-menu-dialog");

  floatingMenuDialogElement.addEventListener('click', (event) => {
    if (event.target.closest('.floating-menu-dialog-contents') === null) {
      floatingMenuDialogElement.close();
    }
  });

  floatingMenuButtonElement.addEventListener('click', (event) => {
    floatingMenuDialogElement.showModal();

    const focusedElement = document.activeElement;

    if (focusedElement !== null) {
      focusedElement.blur();
    }
  });

  const scrollToTopButtonElement = module.querySelector(".floating-menu-item-scroll-to-top");
  scrollToTopButtonElement.addEventListener('click', (event) => {
    app.scrollToHash("#js-scroll-to-top")
    floatingMenuDialogElement.close();
  });

  const scrollToFormButtonElement = module.querySelector(".floating-menu-item-scroll-to-comment-form .menu-link");
  const scrollToFormElement = document.querySelector("#post-comment-form");

  if (scrollToFormButtonElement !== null && scrollToFormElement !== null) {
    scrollToFormButtonElement.addEventListener('click', (event) => {
      app.scrollToHash("#post-comment-form")
      floatingMenuDialogElement.close();
    });
  } else {
    scrollToFormButtonElement.classList.add("disable");
  }

  const scrollToLastCommentButtonElement = module.querySelector(".floating-menu-item-scroll-to-last-comment .menu-link");
  const lastCommentElement = document.querySelector("#last-comment");

  if (scrollToLastCommentButtonElement !== null && lastCommentElement !== null) {
    scrollToLastCommentButtonElement.addEventListener('click', (event) => {
      app.scrollToHash("#last-comment")
      floatingMenuDialogElement.close();
    });
  } else {
    scrollToLastCommentButtonElement.classList.add("disable");
  }

  let calcFloatingPositon = element => {
    let overlayModuleElement = document.querySelector(".pr-footer-overlay-module");
    if (overlayModuleElement == null) {
      return;
    }

    element.classList.add("overlay");

  }
  pubsubStore.subscribe("userLoginCompleted", args => {
    calcFloatingPositon(module)
    module.style.display = "block";
  });

});
