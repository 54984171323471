import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  let modules = document.querySelectorAll(".reread-board-module");
  if (modules.length == 0) {
    return;
  }

  let updateRereadButtonState = element => {
    let rereadButton = element.querySelector(".reread-button");
    let loggedInRereadButton = element.querySelector(".action-button.logged-in");
    let notLoggedInRereadButton = element.querySelector(".action-button.not-logged-in");

    if (loginStateStore.isLoggedIn() == false) {
      rereadButton.style.visibility = "visible";
      loggedInRereadButton.remove()
      return;
    }

    notLoggedInRereadButton.remove();
    Api.boardRereading(element.dataset.boardId).then(json => {
      loggedInRereadButton.style.visibility = "visible";
      if (json.rereading) {
        loggedInRereadButton.classList.add("selected");
      }
    });
  };

  let initState = () => {
    modules.forEach(element => {
      let rootElement = element.querySelector(".reread-board");
      let rereadButton = rootElement.querySelector(".reread-button");
      let loggedInRereadButton = rootElement.querySelector(".action-button.logged-in");

      updateRereadButtonState(rootElement);

      if (loginStateStore.isLoggedIn() == false) {
        return;
      }

      rereadButton.addEventListener("click", function() {
        if (loggedInRereadButton.classList.contains("selected") == true) {
          loggedInRereadButton.classList.remove("selected");
          Api.removeBoardReread(rootElement.dataset.boardId);
        } else {
          Api.addBoardReread(rootElement.dataset.boardId);
          loggedInRereadButton.classList.add("selected");
        }
      });
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });
});
