import Api from "../../Api";
import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function (event) {
  let modules = document.querySelectorAll(".reviews-first-comment-module");

  if (modules.length == 0) {
    return;
  }

  let initState = () => {
    modules.forEach(element => {
      const topicId = element.dataset.topicId;

      Api.userTopicOwned(topicId).then(json => {
        let editButtonElement = element.querySelector(".edit-button");

        if (json.owned == true) {
          editButtonElement.style.display = "block";
        } else {
          editButtonElement.remove();
        }
      });
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });
});
