import algoliasearchApiInstance from "./api";

function enableAutoComplete(searchInputId, searchInputName) {
  const { autocomplete, getAlgoliaResults } = window[
    "@algolia/autocomplete-js"
  ];
  const searchClient = algoliasearchApiInstance.getClient();
  const boardIndexName = algoliasearchApiInstance.getBoardIndexName();
  const authorIndexName = algoliasearchApiInstance.getAuthorIndexName();
  const placeholders = [
    "全体検索",
    "サイト内検索",
    "コンテンツ検索",
    "キーワード検索",
    "タイトル・著者",
  ];

  const autocompleteSearch = autocomplete({
    container: searchInputId,
    detachedMediaQuery: "none",
    placeholder: placeholders[Math.floor(Math.random() * placeholders.length)],
    id: searchInputName + "-autocomplete",
    getSources() {
      return [
        {
          sourceId: "boards",
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: boardIndexName,
                  query,
                  params: {
                    filters: "is_suggestable: true",
                    hitsPerPage: 5
                  }
                }
              ]
            });
          },
          templates: {
            header({ html }) {
              return html`
                <div class="">作品</div>
              `;
            },

            item({ item, components, html }) {
              return html`
                <div class="aa-manba-title-item">
                    <img src=${item.suggest_thumbnail_url} alt='${item.title}' />
                    <div>
                      ${components.Highlight({
                hit: item,
                attribute: "title",
                tagName: "mark"
              })}
                    </div>
                  </div>
              `;
            }
          },
          onSelect({ insights, insightsEvents, item }) {
            if (item.redirect_url !== undefined) {
              window.location.href = item.redirect_url;
            }
          }
        },
        {
          sourceId: "authors",
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: authorIndexName,
                  query,
                  params: {
                    filters: "is_suggestable: true",
                    hitsPerPage: 5
                  }
                }
              ]
            });
          },
          templates: {
            header({ html }) {
              return html`
                <div class="">著者</div>
              `;
            },
            item({ item, components, html }) {
              return html`
              <div class="aa-manba-author-item">
                <img src=${item.thumbnail_url} alt='${item.author_names_text}'　/>
                  <div>
                    ${components.Highlight({
                hit: item,
                attribute: "name",
                tagName: "mark"
              })}
                  </div>
                </div>
              `;
            }
          },
          onSelect({ insights, insightsEvents, item }) {
            if (item.redirect_url !== undefined) {
              window.location.href = item.redirect_url;
            }
          }
        }
      ];
    }
  });
}

function placeholderSearch(searchInput) {
  let targetName = searchInput + "-autocomplete-input";
  let element = document.querySelector(targetName);
  if (element === null) {
    return;
  }

  element.addEventListener("keypress", function (event) {
    let value = element.value;
    if (value === "" && event.key === "Enter") {
      event.preventDefault();
      let placeholderMessage = element.placeholder;
      let redirectUrl = `/search`;
      location.href = redirectUrl;
    }

    if (value !== "" && event.key === "Enter") {
      event.preventDefault();
      let redirectUrl = `/search?q=${element.value}`;
      location.href = redirectUrl;
    }
  });
}

function placeholderSearchFromButton(searchInput, searchButtonId) {
  let targetName = searchInput + "-autocomplete-input";
  let elementInput = document.querySelector(targetName);
  let elementButton = document.getElementById(searchButtonId);

  elementButton.addEventListener("click", function (event) {
    let value = elementInput.value;
    if (value === "") {
      event.preventDefault();
      let placeholderMessage = elementInput.placeholder;
      let redirectUrl = `/search`;
      location.href = redirectUrl;
    } else {
      let redirectUrl = `/search?q=${elementInput.value}`;
      location.href = redirectUrl;
    }
  });
}

document.addEventListener("DOMContentLoaded", function (event) {
  const desktopSearchInputName = "desktop-header-search";
  const desktopSearchInputNameId = "#" + desktopSearchInputName;
  const desktopSearchElement = document.getElementById(desktopSearchInputName);

  if (desktopSearchElement === null) {
    return;
  }

  if (document.getElementById(desktopSearchInputName) !== null) {
    enableAutoComplete(desktopSearchInputNameId, desktopSearchInputName);
  }

  placeholderSearch(desktopSearchInputNameId);

  const desktopSearchButtonId = "desktop-header-search-button";
  placeholderSearchFromButton(desktopSearchInputNameId, desktopSearchButtonId);

  const smartphoneSearchInputName = "smartphone-header-search";
  const smartphoneSearchInputNameId = "#" + smartphoneSearchInputName;

  if (document.getElementById(smartphoneSearchInputName) !== null) {
    enableAutoComplete(smartphoneSearchInputNameId, smartphoneSearchInputName);
  }
  placeholderSearch(smartphoneSearchInputNameId);
});
