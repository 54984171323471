import Api from "../../Api";
import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function(event) {
  const modules = document.querySelectorAll(".topic-follow-button");
  if (modules.length == 0) {
    return;
  }
  let updateFollowButtonState = element => {
    let topicId = element.dataset.topicId;

    Api.topicFollowing(topicId).then(json => {
      let followActionButton = element.querySelector(
        ".login-action-button.follow-action-button"
      );
      let actionButton = followActionButton.querySelector(".action-button");
      let notLoginActionButton = element.querySelector(
        ".not-login-action-button.follow-action-button"
      );
      let actionButtonText = actionButton.querySelector(".text");

      notLoginActionButton.style.display = "none";
      followActionButton.style.display = "block";

      if (json.following) {
        actionButtonText.innerText = "フォロー中";
        actionButton.classList.add("selected");
      }
    });
  };

  let initFollowButtonState = () => {
    modules.forEach(element => {
      updateFollowButtonState(element);

      let topicId = element.dataset.topicId;
      let followActionButton = element.querySelector(
        ".login-action-button.follow-action-button"
      );
      let actionButton = followActionButton.querySelector(".action-button");
      let actionButtonText = actionButton.querySelector(".text");
      //let message = element.querySelector('.message')

      followActionButton.addEventListener("click", function() {
        if (actionButton.classList.contains("selected") == true) {
          Api.removeTopicFollow(topicId).then(json => {});
          actionButton.classList.remove("selected");
          actionButtonText.innerText = "フォローする";
          //message.style.display = 'none';
        } else {
          Api.addTopicFollow(topicId).then(json => {});
          actionButton.classList.add("selected");
          actionButtonText.innerText = "フォロー中";
          //message.style.display = 'block';
        }
      });
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    if (loginStateStore.isLoggedIn()) {
      initFollowButtonState();
    }
  });
});
