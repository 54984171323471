import Api from "../../Api";
import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  const modules = document.querySelectorAll(".board-follow-button-module");
  if (modules.length == 0) {
    return;
  }

  let updateFollowButtonState = element => {
    let boardId = element.dataset.boardId;
    let actionButtonElement = element.querySelector(".action-button");
    let actionButtonElementText = element.querySelector(".text");

    if (loginStateStore.isLoggedIn() === false) {
      actionButtonElement.addEventListener("click", function () {
        const uri = encodeURI(location.pathname + location.search + location.hash);
        location.href = '/login?rt=' + uri;
      });
      return;

    }

    Api.boardFollowing(boardId).then(json => {
      if (json.following) {
        actionButtonElementText.innerText = "フォロー中";
        actionButtonElement.classList.add("selected");
      }
    });
  };

  let initFollowButtonState = () => {
    modules.forEach(element => {
      updateFollowButtonState(element);

      let boardId = element.dataset.boardId;
      let actionButtonElement = element.querySelector(".action-button");
      let actionButtonElementText = element.querySelector(".text");

      if (loginStateStore.isLoggedIn() === true) {
        actionButtonElement.addEventListener("click", function () {
          if (actionButtonElement.classList.contains("selected") == true) {
            Api.removeBoardFollow(boardId).then(json => { });
            actionButtonElement.classList.remove("selected");
            actionButtonElementText.innerText = "フォローする";
          } else {
            Api.addBoardFollow(boardId).then(json => { });
            actionButtonElement.classList.add("selected");
            actionButtonElementText.innerText = "フォロー中";
          }
        });
      }
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initFollowButtonState();
  });
});
