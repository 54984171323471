import Api from "../Api";

class BoardVisitLogger {
  constructor(element) {
    this.element = element;
    this.init(); // 初期化処理
  }

  async init() {
    try {
      const boardId = this.element.dataset.boardId;
      await this.addUserRecentVisitedBoardLogs(boardId);
    } catch (error) {
      console.error("Error adding board visit log:", error);
    }
  }

  async addUserRecentVisitedBoardLogs(boardId) {
    await Api.addUserRecentVisitedBoardLogs(boardId);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const element = document.querySelector("#board-visited");
  if (element) {
    new BoardVisitLogger(element);
  }
});
