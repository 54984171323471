import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

class ReadingNoteModule {
  constructor() {
    this.baseHeight = 125;
    this.modules = document.querySelectorAll(".board-new-reading-note-module");
  }

  initializeModules() {
    this.modules.forEach(element => {
      if (loginStateStore.isLoggedIn()) {
        this.initDialog(element);
        this.initReadingNote(element);
      } else {
        this.attachLoginRedirect(element);
      }
    });
  }

  initReadingNote(element) {
    const readingNoteTextInputElement = element.querySelector(".reading-notes-text-input");
    let clientHeight = readingNoteTextInputElement.clientHeight;

    readingNoteTextInputElement.addEventListener("input", (event) => {
      event.stopPropagation();
      readingNoteTextInputElement.style.height = `${clientHeight}px`;
      let scrollHeight = readingNoteTextInputElement.scrollHeight > this.baseHeight ? readingNoteTextInputElement.scrollHeight : this.baseHeight;
      readingNoteTextInputElement.style.height = `${scrollHeight}px`;
    });

    const boardId = element.dataset.boardId;
    Api.boardReadingNote(boardId).then(json => {
      readingNoteTextInputElement.textContent = json.memo;
    });
  }

  attachLoginRedirect(element) {
    const newReadingNoteLink = element.querySelector(".new-reading-note-link");
    newReadingNoteLink.addEventListener('click', () => {
      const uri = encodeURI(location.pathname + location.search + location.hash);
      location.href = '/login?rt=' + uri;
    });
  }

  initDialog(element) {
    const boardId = element.dataset.boardId;
    const newReadingNoteLink = element.querySelector(".new-reading-note-link");
    const dialogElement = element.querySelector(".board-new-reading-note-dialog");
    const closeButton = dialogElement.querySelector(".close-button");
    const saveButton = dialogElement.querySelector(".save-button");

    newReadingNoteLink.addEventListener('click', () => {
      dialogElement.showModal();
    });

    closeButton.addEventListener('click', () => {
      dialogElement.close();
    });

    dialogElement.addEventListener('click', (event) => {
      if (event.target.closest('.board-new-reading-note-dialog-contents') === null) {
        dialogElement.close();
      }
    });

    saveButton.addEventListener('click', () => {
      const memo = this.getReadingNoteMemo(element);
      Api.updateReadingNote(boardId, memo);
      pubsubStore.publish("boardStatusChange", null);
      dialogElement.close();
    });
  }

  getReadingNoteMemo(element) {
    const readingNoteTextInputElement = element.querySelector(".reading-notes-text-input");
    return readingNoteTextInputElement.value;
  }

  setReadingNoteMemo(element, text) {
    const readingNoteTextInputElement = element.querySelector(".reading-notes-text-input");
    readingNoteTextInputElement.textContent = text;
  }
}

const readingNoteModuleInstance = new ReadingNoteModule();
export default readingNoteModuleInstance;

document.addEventListener("DOMContentLoaded", function () {
  pubsubStore.subscribe("userLoginCompleted", () => readingNoteModuleInstance.initializeModules());
  pubsubStore.subscribe("boardStatusChange", () => { });
});
