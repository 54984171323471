import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let modules = document.querySelectorAll(".follow-user-action-icon-module");

  if (modules.length == 0) {
    return;
  }

  let initFollowAction = element => {
    let key = loginStateStore.getKey();
    let userKey = element.dataset.userKey;
    let followActionElement = element.querySelector(".follow-action");
    let followActionText = element.querySelector(".follow-action .text");
    let followActionIconElement = element.querySelector(".follow-action .icon .material-icons-outlined");

    if (followActionElement == null) {
      return;
    }

    if (key === userKey) {
      followActionElement.remove();
      return;
    }

    if (loginStateStore.isLoggedIn() == true) {
      Api.userFollowing(userKey).then(json => {
        if (json.following) {
          followActionElement.classList.add("selected");
          followActionText.innerText = "";
          followActionIconElement.textContent = "notifications";

        }
      });
    }

    followActionElement.addEventListener("click", function () {
      if (loginStateStore.isLoggedIn() == false) {
        const uri = encodeURI(location.pathname + location.search + location.hash);
        location.href = '/login?rt=' + uri;
        return;
      }

      if (followActionElement.classList.contains("selected") == true) {
        followActionElement.classList.remove("selected");
        Api.removeUserFollow(userKey)
        followActionText.innerText = "フォロー";
        followActionIconElement.textContent = "notifications";


      } else {
        followActionElement.classList.add("selected");
        Api.addUserFollow(userKey)
        followActionText.innerText = "";
        followActionIconElement.textContent = "notifications";

      }
    });
  };

  let initState = () => {
    modules.forEach(element => {
      initFollowAction(element);
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appentRootElement => {
    appentRootElement.querySelectorAll(".follow-user-action-icon-module").forEach(element => {
      initFollowAction(element);
    });
  });

});
