import Api from "../../Api";

document.addEventListener("DOMContentLoaded", function(event) {
  let element = document.querySelector("#user-board-list-view-counter");

  if (element == null) {
    return;
  }
  let userBoardListId = element.dataset.userBoardListId;
  Api.userBoardListViewCounter(userBoardListId).then(json => {});
});
