import csrfTokenStoreInstance from "../../csrf_token_store";

document.addEventListener("DOMContentLoaded", function(event) {
  let isExist = document.querySelector("#profile-tutorials-form");

  if (isExist == null) {
    return;
  }

  let rootElement = () => {
    return document.querySelector(".profile-tutorials-main");
  };

  let initForm = () => {
    var profileImage = document.querySelector('.profile-tutorial-icon-image img');
    let fileInput = rootElement().querySelector(".file-field");
    let upload = rootElement().querySelector(".profile-tutorials-step-button.image-upload");

    profileImage.addEventListener("click", function (event) {
      fileInput.click();
    });

    fileInput.addEventListener('change', function(event) {
      var selectedFile = event.target.files[0];
      var reader = new FileReader();
      reader.onload = function(e) {
        profileImage.src = e.target.result;
      };
      reader.readAsDataURL(selectedFile);
    });

    upload.addEventListener("click", function (event) {
      if (fileInput.files.length === 0) {
        alert('ファイルを選択してください');
        event.preventDefault();
      }
    });
  };
  initForm();
});
