import loginStateStore from "../../../login_state_store";
import pubsubStore from "../../../pubsub_store";

document.addEventListener("DOMContentLoaded", function(event) {
    "use strict";

    let modules = document.querySelectorAll(".free-space-introduction-item-main-module");

    if (modules.length == 0) {
        return;
    }

    let initState = () => {
        modules.forEach(element => {
            if (loginStateStore.isLoggedIn() == true) {
                element.remove()
            }
        });
    };

    pubsubStore.subscribe("userLoginCompleted", args => {
        initState();
    });


});
