
document.addEventListener("DOMContentLoaded", function(event) {
    "use strict";
    let modules = document.querySelectorAll(".free-spaces-pager-select-module");
    if (modules.length === 0) {
        return;
    }

    let initPagerSelect = element => {
        let selectTagElement = element.querySelector(".free-spaces-pager-select select");

        selectTagElement.addEventListener("change", function(event) {
            let redirectUrl = event.target.value
            location.href = redirectUrl;
        });
    };

    let initState = () => {
        modules.forEach(element => {
            initPagerSelect(element);
        });
    };

    initState();

});
