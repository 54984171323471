import algoliasearchApiInstance from "../../algoliasearch/api";

function enableAutoComplete(searchInputId, searchInputName) {
  const { autocomplete, getAlgoliaResults } = window[
    "@algolia/autocomplete-js"
  ];
  const searchClient = algoliasearchApiInstance.getClient();
  const boardIndexName = algoliasearchApiInstance.getBoardIndexName();

  const autocompleteSearch = autocomplete({
    container: searchInputId,
    detachedMediaQuery: "none",
    placeholder: '作品タイトルを入力してください',
    autoFocus: true,
    id: searchInputName + "-autocomplete",
    getSources() {
      return [
        {
          sourceId: "boards",
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: boardIndexName,
                  query,
                  params: {
                    filters: "is_suggestable: true",
                    hitsPerPage: 5
                  }
                }
              ]
            });
          },
          templates: {
            item({ item, components, html }) {
              return html`
                <div class="aa-manba-title-item">
                    <img src=${item.suggest_thumbnail_url} alt='${item.title}' />
                    <div>
                      ${components.Highlight({
                hit: item,
                attribute: "title",
                tagName: "mark"
              })}
                    </div>
                  </div>
              `;
            }
          },
          onSelect({ insights, insightsEvents, item }) {
            if (item.redirect_url !== undefined) {
              const rootElement = document.querySelector(".topics-select-box-module");
              let type = rootElement.dataset.type;
              const redirectUrl = '/topics/new?board_id=' + item.objectID + '&type=' + type;
              window.location.href = redirectUrl
            }
          }
        },
      ];
    }
  });
}

function keypressSearch(searchInput) {
  let targetName = "#" + searchInput + "-autocomplete-input";
  let element = document.querySelector(targetName);

  if (element === null) {
    return;
  }

  element.addEventListener("keypress", function (event) {
    let value = element.value;
    if (value !== "" && event.key === "Enter") {
      event.preventDefault();
      let redirectUrl = `/search?q=${element.value}`;
      location.href = redirectUrl;
    }
  });
}

document.addEventListener("DOMContentLoaded", function (event) {
  const topicsSearchName = "topics-select-search";
  const topicsSearchNameId = "#" + topicsSearchName;

  if (document.getElementById(topicsSearchName) === null) {
    return
  }

  enableAutoComplete(topicsSearchNameId, topicsSearchName);
  keypressSearch(topicsSearchName);

});
