import Api from "../../Api";

document.addEventListener("DOMContentLoaded", function(event) {
  let element = document.getElementById("board-view-counter");

  if (element == null) {
    return;
  }
  let boardId = element.dataset.boardId;
  Api.boardViewCounter(boardId).then(json => {});
});
