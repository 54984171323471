
document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let smaprtphonePositonElement = document.querySelector(".smartphone-comment-form-position");
  if (smaprtphonePositonElement == null) {
    return;
  }

  let desktopePositonElement = document.querySelector(".desktop-comment-form-position");
  if (desktopePositonElement == null) {
    return;
  }

  let commentFormElement = document.querySelector("#post-comment-form");
  if (commentFormElement == null) {
    return;
  }

  let isNowSmarthone = undefined;

  function changePosition() {
    let isSmartPhone = app.isSmartPhone();

    if (isNowSmarthone == isSmartPhone) {
      return;
    }

    if (isSmartPhone) {
      smaprtphonePositonElement.appendChild(commentFormElement);
    } else {
      desktopePositonElement.appendChild(commentFormElement);
    }
    isNowSmarthone = isSmartPhone;
  }

  window.addEventListener(
    "resize",
    function () {
      changePosition();
    },
    { passive: true }
  );

  changePosition();
});
