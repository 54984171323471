class csrfTokenStore {
  constructor() {
    this._data = {
      csrfParam: null,
      csrfToken: null
    };
  }

  setState(csrfParam, csrfToken) {
    this._data.csrfParam = csrfParam;
    this._data.csrfToken = csrfToken;
  }

  getCsrfParam() {
    return this._data.csrfParam;
  }

  getCsrfToken() {
    return this._data.csrfToken;
  }

  get() {
    return this._data;
  }
}

const csrfTokenStoreInstance = new csrfTokenStore();
Object.freeze(csrfTokenStoreInstance);
export default csrfTokenStoreInstance;
