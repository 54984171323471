import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  let modules = document.querySelectorAll(".empathy-action-module");

  if (modules.length == 0) {
    return;
  }

  let initFollowAction = element => {
    let commentId = element.dataset.commentId
    let empathyIconElement = element.querySelector(".empathy-icon");
    let empathyCountElement = element.querySelector(".empathy-count");

    empathyIconElement.addEventListener("click", function() {
      // 画像の拡大
      let imageElement = empathyIconElement.querySelector(
          ".material-icons-outlined"
      );
      imageElement.classList.add("animate");

      setTimeout(() => {
        imageElement.classList.remove("animate");
      }, 1000);

      if (window.navigator.vibrate !== undefined) {
        window.navigator.vibrate(50);
      }

      empathyCountElement.classList.remove("empty");

      let countElement = empathyCountElement.querySelector(".count");
      countElement.innerHTML = parseInt(countElement.innerHTML) + 1;

      let url = "/api/comments/" + commentId + "/empathies";

      return fetch(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => {});
    });
  };

  let initState = () => {
    modules.forEach(element => {
      initFollowAction(element);
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appentRootElement => {
    appentRootElement.querySelectorAll(".empathy-action-module").forEach(element => {
      initFollowAction(element);
    });
  });

});
