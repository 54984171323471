document.addEventListener("DOMContentLoaded", function (event) {
    "use strict";
    const elements = document.querySelectorAll(".board-sp-main-module");

    if (elements == null) {
        return;
    }

    elements.forEach(element => {
        const boardElement = element.querySelector(".board-sp-main");
        const backgroundImageUrl = boardElement.getAttribute("data-background-image-url");
        boardElement.style.setProperty('--custom-bg-image', `url(${backgroundImageUrl})`);
    });
});
