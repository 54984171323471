import algoliasearchApiInstance from "./api";

document.addEventListener("DOMContentLoaded", function(event) {
  function enableAutoComplete(searchInput) {
    const { autocomplete, getAlgoliaResults } = window[
      "@algolia/autocomplete-js"
    ];
    const searchClient = algoliasearchApiInstance.getClient();
    const indexName = algoliasearchApiInstance.getManbaMagazineArticleIndexName();

    const autocompleteSearch = autocomplete({
      container: searchInput,
      detachedMediaQuery: "none",
      placeholder: "キーワードで絞り込み",
      id: "manba-magazine-article-autocomplete",
      getSources() {
        return [
          {
            getItems({ query }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: indexName,
                    query,
                    params: {
                      hitsPerPage: 5
                    }
                  }
                ]
              });
            },
            templates: {
              item({ item, components, html }) {
                return html`
                  <div>
                    ${components.Highlight({
                      hit: item,
                      attribute: "title",
                      tagName: "mark"
                    })}
                  </div>
                `;
              }
            },
            onSelect({ insights, insightsEvents, item }) {
              location.href = item.redirect_url;
            }
          }
        ];
      }
    });

    let buttonElement = document.querySelector("#manba-magazine-search-button");
    let inputElement = document.querySelector(
      "#manba-magazine-article-autocomplete-input"
    );
    buttonElement.addEventListener("click", function(event) {
      let value = inputElement.value;
      if (value !== "") {
        event.preventDefault();
        let redirectUrl = `/search/manba_magazines?q=${value}`;
        location.href = redirectUrl;
      }
    });
  }

  function enableEnterSearch() {
    let targetNameId = "#manba-magazine-article-autocomplete-input";
    let element = document.querySelector(targetNameId);
    if (element === null) {
      return;
    }

    element.addEventListener("keypress", function(event) {
      let value = element.value;
      if (value !== "" && event.key === "Enter") {
        event.preventDefault();
        let redirectUrl = `/search/manba_magazines?q=${element.value}`;
        location.href = redirectUrl;
      }
    });
  }

  const manbaMagazineSearchNameId = "#manba-magzine-search";
  let element = document.querySelector(manbaMagazineSearchNameId);
  if (element === null) {
    return;
  }

  enableAutoComplete(manbaMagazineSearchNameId);
  enableEnterSearch();
});
