document.addEventListener("DOMContentLoaded", function(event) {
  let isExist = document.getElementById("js-layout-profiles");

  if (isExist == null) {
    return;
  }

  let isNowSmarthone = undefined;

  function updateResize() {
    let smartphoneProfileInfo = document.getElementById(
      "smartphone-profile-info"
    );
    let desktopProfileInfo = document.getElementById("desktop-profile-info");
    let profileModule = document.querySelector(".profiles-user-module");

    if (
      smartphoneProfileInfo == null ||
      desktopProfileInfo == null ||
      profileModule == null
    ) {
      return;
    }

    let isSmartPhone = app.isSmartPhone();

    if (isNowSmarthone == isSmartPhone) {
      return;
    }

    if (isSmartPhone) {
      smartphoneProfileInfo.appendChild(profileModule);
    } else {
      desktopProfileInfo.appendChild(profileModule);
    }
    isNowSmarthone = isSmartPhone;
  }

  window.addEventListener(
    "resize",
    function() {
      updateResize();
    },
    { passive: true }
  );

  updateResize();
});
