document.addEventListener("DOMContentLoaded", function(event) {
  if (!app.inLayout("application-affiliates")) {
    return;
  }

  let topElement = document.querySelector(".valuecommerce-logo-partial");

  let href = topElement.querySelector("a").href;
  location.href = href

});
