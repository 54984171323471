import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  let modules = document.querySelectorAll(".store-action-module");

  if (modules.length == 0) {
    return;
  }

  let initStoreAction = element => {
    let storeActionItemsElement = element.querySelector(".store-action-items");

    element.addEventListener("click", function() {
      storeActionItemsElement.classList.toggle("show");
    });
  };

  let initState = () => {
    modules.forEach(element => {
      initStoreAction(element);
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appentRootElement => {
    appentRootElement.querySelectorAll(".store-action-module").forEach(element => {
      initStoreAction(element);
    });
  });

});
