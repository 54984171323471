document.addEventListener("DOMContentLoaded", function (event) {
    "use strict";
    const elements = document.querySelectorAll(".pr-small-board-module");
    if (elements == null) {
        return;
    }

    elements.forEach(element => {
        const boardElement = element.querySelector(".pr-board");
        const backgroundImageUrl = boardElement.getAttribute("data-background-image-url");
        boardElement.style.setProperty('--custom-bg-image', `url(${backgroundImageUrl})`);
    });
});
