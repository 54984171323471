import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function(event) {
    "use strict";

    let modules = document.querySelectorAll(".favorite-ratings-module");

    if (modules.length == 0) {
        return;
    }

    let updateFavoriteRatingState = (element,favoriteRating) => {
        const favoriteRatingElements = element.querySelectorAll(".favorite-rating");

        favoriteRatingElements.forEach(favoriteRatingElement => {
            const favoriteRatingValue = favoriteRatingElement.dataset.favoriteRating

            if (favoriteRatingValue <= favoriteRating) {
                favoriteRatingElement.classList.add("selected");
            } else {
                favoriteRatingElement.classList.remove("selected");
            }
        });
    }

    let initFavoriteRatingAction = element => {
        const boardId = element.dataset.boardId

        if (loginStateStore.isLoggedIn() == false) {
            element.style.display = "none";
            return;
        }

        Api.boardFavoriteRating(boardId).then(json => {
            const favoriteRating = json.favorite_rating;

            if (favoriteRating == 0) {
                element.style.display = "none";
                return;
            }
            element.style.display = "block";
            updateFavoriteRatingState(element,favoriteRating)
        });
    };

    let initState = () => {
        modules.forEach(element => {
            initFavoriteRatingAction(element);
        });
    };

    pubsubStore.subscribe("userLoginCompleted", args => {
        initState();
    });

    pubsubStore.subscribe("infiniteScrollPageAppend", appentRootElement => {
        appentRootElement.querySelectorAll(".favorite-ratings-module").forEach(element => {
            initFavoriteRatingAction(element);
        });
    });
});
