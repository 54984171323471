import Api from "../../Api";
import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function(event) {
  let commentElems = document.querySelectorAll(".owned-comment");

  if (commentElems.length === 0) {
    return;
  }

  let initState = () => {
    let ownedTopicId = commentElems[0].dataset.ownedTopicId;
    let ownedCommentIds = Array.prototype.map.call(commentElems, function(
      elem
    ) {
      return elem.dataset.ownedCommentId;
    });

    Api.userTopicCommentsEditable(ownedTopicId, ownedCommentIds).then(json => {
      let commentIds = json.comment_ids;
      commentElems.forEach(function(targetComment) {
        let targetCommentId = parseInt(targetComment.dataset.ownedCommentId);

        if (commentIds.includes(targetCommentId)) {
          targetComment.style.display = "block";
        } else {
          targetComment.remove();
        }
      });
    });
  };
  pubsubStore.subscribe("userLoginCompleted", args => {
    if (loginStateStore.isLoggedIn()) {
      initState();
    }
  });
});
