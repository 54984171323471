import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  pubsubStore.subscribe("userLoginCompleted", args => {
    if (loginStateStore.isLoggedIn() == true) {
      let module = document.querySelector(".col-xs-12.main-contents.main-banner");

      if (module == null) {
        return;
      }

      module.remove();
    }
  });

});
