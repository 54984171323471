import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  const module = document.querySelector(".footer-function-menu-module");

  if (!module) {
    return;
  }

  let initState = () => {
    const overlayElement = document.querySelector(".pr-footer-overlay-module");

    if (overlayElement === null) {
      return;
    }

    module.classList.add("overlay");

  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

});
