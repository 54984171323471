import Api from "../Api";

class AuthorVisitLogger {
  constructor(element) {
    this.element = element;
    this.init(); // 初期化処理
  }

  async init() {
    try {
      const authorId = this.element.dataset.authorId;
      await this.addUserRecentVisitedAuthorLogs(authorId);
    } catch (error) {
      console.error("Error adding author visit log:", error);
    }
  }

  async addUserRecentVisitedAuthorLogs(authorId) {
    await Api.addUserRecentVisitedAuthorLogs(authorId);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const element = document.querySelector("#author-visited");
  if (element) {
    new AuthorVisitLogger(element);
  }
});
