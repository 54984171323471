import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

class FollowAuthorModule {
  constructor(modules) {
    this.modules = modules;

    if (!this.modules.length) return;

    this.initState();
  }

  async initFollowButtonState(element) {
    const authorId = element.dataset.authorId;
    const followButton = element.querySelector(".follow-button .follow-action");
    const textElement = element.querySelector(".follow-button .follow-action .text");

    if (!followButton || !textElement) return;

    if (loginStateStore.isLoggedIn()) {
      try {
        const json = await Api.authorFollowing(authorId);
        if (json.following) {
          textElement.innerText = "フォロー中";
          followButton.classList.add("selected");
        } else {
          textElement.innerText = "フォロー";
          followButton.classList.remove("selected");
        }
      } catch (error) {
        console.error("Error fetching follow status:", error);
      }
    }

    followButton.addEventListener("click", () => this.handleFollowButtonClick(followButton, textElement, authorId));
  }

  handleFollowButtonClick(followButton, textElement, authorId) {
    if (!loginStateStore.isLoggedIn()) {
      const uri = encodeURI(location.pathname + location.search + location.hash);
      location.href = '/login?rt=' + uri;
      return;
    }

    if (followButton.classList.contains("selected")) {
      textElement.innerText = "フォロー";
      followButton.classList.remove("selected");
      Api.removeAuthorFollow(authorId);
    } else {
      textElement.innerText = "フォロー中";
      followButton.classList.add("selected");
      Api.addAuthorFollow(authorId);
    }
  }

  initState() {
    this.modules.forEach(element => this.initFollowButtonState(element));
  }
}

const initFollowAuthorModule = (modules) => {
  if (loginStateStore.isLoggedIn()) {
    new FollowAuthorModule(modules);
  }
};

document.addEventListener("DOMContentLoaded", function () {
  pubsubStore.subscribe("userLoginCompleted", () => {
    const modules = document.querySelectorAll(".follow-author-module");
    if (modules.length) {
      initFollowAuthorModule(modules);
    }
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appendRootElement => {
    const modules = appendRootElement.querySelectorAll(".follow-author-module");
    if (modules.length) {
      initFollowAuthorModule(modules);
    }
  });

  pubsubStore.subscribe("renderAsyncLoad", eventContainer => {
    const modules = eventContainer.querySelectorAll(".follow-author-module");
    if (modules.length) {
      initFollowAuthorModule(modules);
    }
  });
});

