import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
    "use strict";

    let modules = document.querySelectorAll(".favorite-ratings-action-module");

    if (modules.length == 0) {
        return;
    }

    let updateFavoriteRatings = (element, nowFavoriteRating, newFavoriteRating) => {
        const favoriteRatingElements = element.querySelectorAll(".favorite-rating");

        if (nowFavoriteRating == newFavoriteRating) {
            favoriteRatingElements.forEach(favoriteRatingElement => {
                const favoriteRatingElementIcon = favoriteRatingElement.querySelector(".icon-module .material-icons-outlined");
                favoriteRatingElement.classList.remove("selected");
                favoriteRatingElementIcon.textContent = "star_border";

            });
            setFavoriteRatingData(element, 0)
            return;
        }

        favoriteRatingElements.forEach(favoriteRatingElement => {
            const favoriteRatingValue = favoriteRatingElement.dataset.favoriteRating
            const favoriteRatingElementIcon = favoriteRatingElement.querySelector(".icon-module .material-icons-outlined");

            if (favoriteRatingValue <= newFavoriteRating) {
                favoriteRatingElement.classList.add("selected");
                favoriteRatingElementIcon.textContent = "star";
            } else {
                favoriteRatingElement.classList.remove("selected");
                favoriteRatingElementIcon.textContent = "star_border";
            }
        });
        setFavoriteRatingData(element, newFavoriteRating)

    }

    let getPublicStatus = (element) => {
        const publicStatusText = element.dataset.publicStatus;

        if (publicStatusText == "public") {
            return true;
        } else {
            return false;
        }
    }

    let getFavoriteRating = (element) => {
        const favoriteRating = element.dataset.favoriteRating;
        return favoriteRating;
    }

    let setFavoriteRatingValue = (element, favoriteRatingValue, publicStatus) => {
        const boardId = element.dataset.boardId;

        Api.updateFavoriteRating(boardId, favoriteRatingValue, publicStatus)
    }

    let setFavoriteRatingData = (element, favoriteRatingValue) => {
        element.dataset.favoriteRating = favoriteRatingValue
    }

    let getFavoriteRatingData = (element) => {
        return element.dataset.favoriteRating
    }

    let getPublicStatusData = (element) => {
        return element.dataset.publicStatus;
    }

    let setPublicStatusData = (element, publicStatusText) => {
        element.dataset.publicStatus = publicStatusText
    }

    let togglePublicStatusData = (element) => {
        let publicStatusText = getPublicStatusData(element);
        if (publicStatusText == "public") {
            setPublicStatusData(element, "private")
        } else {
            setPublicStatusData(element, "public")
        }
    }

    let updatePublicStatus = (element, publicStatusText) => {

        const publicStatusElement = element.querySelector(".public-status");
        const publicStatusElementIcon = publicStatusElement.querySelector(".icon-module .material-icons-outlined");

        if (publicStatusText == "public") {
            publicStatusElementIcon.textContent = "public";
        }

        if (publicStatusText == "private") {
            publicStatusElementIcon.textContent = "lock";
        }
        setPublicStatusData(element, publicStatusText);

    }

    let initFavoriteRatingAction = element => {
        const userKey = element.dataset.userKey;
        const boardId = element.dataset.boardId;

        Api.userBoardFavoriteRating(userKey, boardId).then(json => {
            const isOwner = json.is_owner;
            const publicStatus = json.public_status;
            const favoriteRating = json.favorite_rating;
            const favoriteRatingElements = element.querySelectorAll(".favorite-rating");
            const publicStatusElement = element.querySelector(".public-status");

            element.style.display = "block";

            if (isOwner == true) {
                publicStatusElement.style.display = "block";

                setPublicStatusData(element, publicStatus)
                updatePublicStatus(element, publicStatus);

                setFavoriteRatingData(element, favoriteRating)
                updateFavoriteRatings(element, 0, favoriteRating);

                publicStatusElement.addEventListener("click", function (event) {
                    event.stopPropagation();
                    togglePublicStatusData(element);
                    updatePublicStatus(element, getPublicStatusData(element));
                    setFavoriteRatingValue(element, getFavoriteRatingData(element), getPublicStatus(element));
                });

                favoriteRatingElements.forEach(favoriteRatingElement => {
                    favoriteRatingElement.style.cursor = 'pointer'
                    favoriteRatingElement.addEventListener("click", function (event) {
                        event.stopPropagation();

                        const favoriteRating = favoriteRatingElement.dataset.favoriteRating;

                        updateFavoriteRatings(element, getFavoriteRatingData(element), favoriteRating);
                        setFavoriteRatingValue(element, getFavoriteRatingData(element), getPublicStatus(element));
                    });
                });
            }

            if (isOwner == false && publicStatus == "public") {
                publicStatusElement.remove()
                setFavoriteRatingData(element, favoriteRating)
                updateFavoriteRatings(element, 0, favoriteRating);
            }

            if (isOwner == false && publicStatus == "private") {
                publicStatusElement.remove()
                setFavoriteRatingData(element, 0)
                updateFavoriteRatings(element, 0, 0);
            }

        });
    };

    let initReviewdAction = element => {
        const userKey = element.dataset.userKey;
        const boardId = element.dataset.boardId;

        Api.userBoardReviewed(userKey, boardId).then(json => {
            const isOwner = json.is_owner;
            const reviewed = json.reviewed;
            const reviewedElement = element.querySelector(".reviewed");
            const reviewedElementIcon = reviewedElement.querySelector(".icon-module .material-icons-outlined");

            if (isOwner == true) {
                reviewedElement.style.display = "block";

                if (reviewed == true) {
                    reviewedElementIcon.textContent = "mark_chat_read";
                } else {
                    reviewedElementIcon.textContent = "edit";
                }

                reviewedElement.addEventListener("click", function (event) {
                    event.stopPropagation();
                    location.href = json.redirect_url;
                });
            }
        });
    };

    let initModule = element => {
        initFavoriteRatingAction(element);
        initReviewdAction(element);
    };

    let initState = () => {
        modules.forEach(element => {
            initModule(element);
        });
    };

    pubsubStore.subscribe("userLoginCompleted", args => {
        initState();
    });

    pubsubStore.subscribe("infiniteScrollPageAppend", appendRootElement => {
        appendRootElement.querySelectorAll(".favorite-ratings-action-module").forEach(element => {
            initModule(element);
        });
    });
});
