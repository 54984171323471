import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  const observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        var attributeText = entry.target.getAttribute('data-tracking-on-view') || "";
        const category = entry.target.getAttribute("data-category") || "undefined";
        const label = entry.target.getAttribute("data-label") || attributeText;

        if (attributeText !== "") {
          gtag('event', 'impression', { 'event_category': category, 'event_label': label })
        }

        observer.unobserve(entry.target);
      }
    });
  }, { rootMargin: "0px", threshold: 1 });


  pubsubStore.subscribe("renderAsyncLoad", eventContainer => {
    const elements = eventContainer.querySelectorAll(".pr-small-author-board-module");

    if (elements == null) {
      return;
    }

    elements.forEach(function (element) {
      observer.observe(element);

      const boardElement = element.querySelector(".pr-board");
      const backgroundImageUrl = boardElement.getAttribute("data-background-image-url");
      boardElement.style.setProperty('--custom-bg-image', `url(${backgroundImageUrl})`);

      element.addEventListener('click', function (event) {
        const attributeText = element.getAttribute("data-tracking-on-click") || "";
        const category = element.getAttribute("data-category") || "undefined";
        const label = element.getAttribute("data-label") || attributeText;
        const value = element.getAttribute("data-value") || 1;

        gtag('event', 'click', { 'event_category': category, 'event_label': label, 'value': value })
      });
    });
  });

});
