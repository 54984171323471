document.addEventListener("DOMContentLoaded", function (event) {
  // data-tracking-on-clickが設定されている要素がクリックされたときに実行する
  document.querySelectorAll('[data-tracking-on-click]').forEach(function (element) {
    element.addEventListener('click', function (event) {
      const attributeText = element.getAttribute("data-tracking-on-click") || "";
      const category = element.getAttribute("data-category") || "undefined";
      const label = element.getAttribute("data-label") || attributeText;
      const value = element.getAttribute("data-value") || 1;

      gtag('event', 'click', { 'event_category': category, 'event_label': label, 'value': value })
      // console.log('event', 'click', { 'event_category': category, 'event_label': label, 'value': value })
    });

  });

  // data-tracking-on-viewが設定されている要素が表示されたときに実行する
  const observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        var attributeText = entry.target.getAttribute('data-tracking-on-view') || "";
        const category = entry.target.getAttribute("data-category") || "undefined";
        const label = entry.target.getAttribute("data-label") || attributeText;

        if (attributeText !== "") {
          gtag('event', 'impression', { 'event_category': category, 'event_label': label })
          // console.log('event', 'impression', { 'event_category': category, 'event_label': label })
        }

        observer.unobserve(entry.target);
      }
    });
  }, { rootMargin: "0px", threshold: 1 });

  document.querySelectorAll('[data-tracking-on-view]').forEach(function (element) {
    observer.observe(element);
  });
});
