import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
    "use strict";

    let modules = document.querySelectorAll(".boards-favorite-ratings-module");
    if (modules.length == 0) {
        return;
    }

    let initState = () => {
        modules.forEach(element => {
            element.style.display = "block";
        });
    };

    pubsubStore.subscribe("userLoginCompleted", args => {
        if (loginStateStore.isLoggedIn() == true) {
            initState();
        }
    });

});
