class loginStateStore {
  constructor() {
    this._data = {
      userId: null,
      key: null,
      adminPagePath: null,
      iconPath: null,
      notificationCount: null,
      loginProviderName: null,
      loginProvideriCon: null,
      hasProfile: null,
      userPrivateIconPath: null
    };
  }

  setState(json) {
    this._data.userId = json.user_id;
    this._data.iconPath = json.icon;
    this._data.key = json.key;
    this._data.notificationCount = json.notification_count;
    this._data.adminPagePath = json.admin_page_path;
    this._data.loginProviderName = json.login_provider_name;
    this._data.loginProviderIcon = json.login_provider_icon;
    this._data.hasProfile = json.has_profile;
    this._data.userPrivateIconPath = json.user_private_icon_path;
  }

  isLoggedIn() {
    return this._data.key !== undefined && this._data.key !== null;
  }

  isAdmin() {
    return (
      this._data.adminPagePath !== undefined &&
      this._data.adminPagePath !== null
    );
  }

  isUser() {
    return this.isLoggedIn();
  }

  getUserIconPath() {
    return this._data.iconPath;
  }

  getUserPrivateIconPath() {
    return this._data.userPrivateIconPath;
  }

  getAdminPagePath() {
    return this._data.adminPagePath;
  }

  getNotificationCount() {
    return this._data.notificationCount;
  }

  get() {
    return this._data;
  }

  getKey() {
    return this._data.key;
  }

  getLoginProviderName() {
    return this._data.loginProviderName;
  }

  getLoginProviderIconPath() {
    return this._data.loginProviderIcon;
  }

  hasProfile() {
    return this._data.hasProfile;
  }

  getnotificationCount() {
    return this._data.notificationCount;
  }
}

const loginStateStoreInstance = new loginStateStore();
Object.freeze(loginStateStoreInstance);
export default loginStateStoreInstance;
