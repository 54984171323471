import Api from "../../Api";

document.addEventListener("DOMContentLoaded", function(event) {
  let element = document.getElementById("topic-view-counter");

  if (element == null) {
    return;
  }

  let topicId = element.dataset.topicId;
  Api.topicViewCounter(topicId).then(json => {});
});
