document.addEventListener("DOMContentLoaded", function (event) {
    "use strict";
    let module = document.querySelector(".footer-function-menu-read-dialog-module");

    if (module == null) {
        return;
    }

    let floatingMenuButtonElement = document.querySelector(".read-function-menu-item");
    let floatingMenuDialogElement = module.querySelector(".footer-function-menu-read");

    floatingMenuDialogElement.addEventListener('click', (event) => {
        if (event.target.closest('.footer-function-menu-read-contents') === null) {
            event.stopPropagation();
            floatingMenuDialogElement.close();
        }
    });

    floatingMenuButtonElement.addEventListener('click', (event) => {
        floatingMenuDialogElement.showModal();

        const focusedElement = document.activeElement;

        if (focusedElement !== null) {
            focusedElement.blur();
        }
    });

});
