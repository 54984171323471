document.addEventListener("DOMContentLoaded", function (event) {
  if (!app.inLayout("application-affiliates")) {
    return;
  }

  const affiliateElement = document.querySelector("#affiliate_url a");

  if (affiliateElement) {
    const redirectUrl = affiliateElement.href;
    //setTimeout(() => {
    window.location.href = redirectUrl;
    //}, 5000);
  }
});
