import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let modules = document.querySelectorAll(".follow-board-module");

  if (modules.length == 0) {
    return;
  }

  let initFollowButtonState = element => {
    let boardId = element.dataset.boardId;
    let followButton = element.querySelector(".follow-button .action-button");
    let actionButtonText = followButton.querySelector(".text");

    if (loginStateStore.isLoggedIn() == true) {
      Api.boardFollowing(boardId).then(json => {
        followButton.style.visibility = "visible";

        if (json.following) {
          followButton.classList.add("selected");
          actionButtonText.innerText = "フォロー中";
        } else {
          followButton.classList.remove("selected");
          actionButtonText.innerHTML = "フォローする";
        }
      });
    }
    followButton.addEventListener("click", function () {
      if (loginStateStore.isLoggedIn() == false) {
        const uri = encodeURI(location.pathname + location.search + location.hash);
        location.href = '/login?rt=' + uri;
        return;
      }

      if (followButton.classList.contains("selected") == true) {
        followButton.classList.remove("selected");
        actionButtonText.innerHTML = "フォローする";

        Api.removeBoardFollow(boardId);
      } else {
        followButton.classList.add("selected");
        actionButtonText.innerText = "フォロー中";

        Api.addBoardFollow(boardId);
      }
    });
  }

  let initState = () => {
    modules.forEach(element => {
      initFollowButtonState(element);
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appendRootElement => {
    appendRootElement.querySelectorAll(".follow-board-module").forEach(element => {
      initFollowButtonState(element)
    });
  });

});
