import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let modules = document.querySelectorAll(".follow-topic-action-icon-module");

  if (modules.length === 0) {
    return;
  }

  let initFollowAction = element => {
    let topicId = element.dataset.topicId
    let followAction = element.querySelector(".follow-action");
    let followActionText = element.querySelector(".follow-action .text");
    let followActionIconElement = element.querySelector(".follow-action .icon .material-icons-outlined");

    Api.topicFollowing(topicId).then(json => {
      if (json.following) {
        followAction.classList.add("selected");
        followActionText.innerText = "";
        followActionIconElement.textContent = "notifications";
      }
    });

    followAction.addEventListener("click", function () {
      if (loginStateStore.isLoggedIn() == false) {
        const uri = encodeURI(location.pathname + location.search + location.hash);
        location.href = '/login?rt=' + uri;
        return;
      }

      if (followAction.classList.contains("selected") == true) {
        followAction.classList.remove("selected");
        Api.removeTopicFollow(topicId);
        followActionText.innerText = "フォロー";

      } else {
        followAction.classList.add("selected");
        Api.addTopicFollow(topicId);
        followActionText.innerText = "";
        followActionIconElement.textContent = "notifications";
      }
    });
  };

  let initState = () => {
    modules.forEach(element => {
      initFollowAction(element);
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appentRootElement => {
    appentRootElement.querySelectorAll(".follow-topic-action-icon-module").forEach(element => {
      initFollowAction(element);
    });
  });

});
