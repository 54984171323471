import Api from "../../Api";
import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  let modules = document.querySelectorAll(".forums-topic-module");
  if (modules.length === 0) {
    return;
  }

  window.addEventListener('pageshow', function (event) {
    if (event.persisted) {
      window.location.reload();
      return
    }

    const [perfEntry] = performance.getEntriesByType("navigation");
    if (perfEntry?.type === "back_forward") {
      window.location.reload();
      return
    }

  });

  let initState = () => {
    modules.forEach(async element => {
      const topicId = element.dataset.topicId;
      if (topicId !== undefined) {
        try {
          const json = await Api.topicStatus(topicId);
          let lastUpdateAtChangedElement = element.querySelector(".last-updated-at-changed");
          if (json.unread === false && json.last_updated_changed === true) {
            lastUpdateAtChangedElement.style.display = "block";
          } else {
            lastUpdateAtChangedElement.remove();
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    if (loginStateStore.isLoggedIn()) {
      initState();
    }
  });

});
