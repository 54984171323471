
import Api from "../../Api";
import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

class TopicPlanToReadButtonModule {
  constructor(modules) {
    this.modules = modules;

    if (!this.modules.length) return;

    this.initModules();
  }

  initModules() {
    this.modules.forEach(module => {
      const ui = new TopicPlanToReadButtonUI(module);
      const topicId = ui.getTopicId();
      const boardId = ui.getBoardId();
      const listItemId = ui.getlistItemId();

      if (!loginStateStore.isLoggedIn()) {
        ui.redirectToLoginOnClick();
        return;
      }

      Api.boardReadStatus(boardId).then(({ read_status: readStatus }) => {
        ui.updateUI(readStatus);

        if (ui.isActionAllowed(readStatus)) {
          ui.addClickListener(() => {
            const isSelected = ui.toggleSelectedState();
            const newStatus = isSelected ? "plan_to_read" : "nothing";
            this.updateReadState(boardId, topicId, listItemId, newStatus, ui);
          });
        }
      });
    });
  }

  updateReadState(boardId, topicId, listItemId, newStatus, ui) {
    if (listItemId) {
      Api.updateReadStatusPlanToReadFromListItem(boardId, listItemId);
      ui.broadcastStateChange(newStatus, boardId);

      return;
    }

    if (topicId) {
      Api.updateReadStatusPlanToReadFromTopic(boardId, topicId);
      ui.broadcastStateChange(newStatus, boardId);

      return;
    }

    Api.updateReadStatus(boardId, newStatus);

    ui.broadcastStateChange(newStatus, boardId);
  }
}

class TopicPlanToReadButtonUI {
  constructor(moduleElement) {
    this.module = moduleElement;
    this.actionButton = this.module.querySelector(".action-button");
    this.iconElement = this.module.querySelector(".icon .material-icons-outlined");
    this.textElement = this.module.querySelector(".text");
    this.planToReadButton = this.module.querySelector(".topic-plan-to-read-button");
  }

  getTopicId() {
    return this.planToReadButton.dataset.topicId;
  }

  getBoardId() {
    return this.planToReadButton.dataset.boardId;
  }

  getlistItemId() {
    return this.planToReadButton.dataset.userBoardListItemId;
  }

  isActionAllowed(readStatus) {
    return ["nothing", "plan_to_read"].includes(readStatus);
  }

  updateUI(readStatus) {
    this.textElement.innerText = this.getReadStateText(readStatus);

    if (!this.isActionAllowed(readStatus)) {
      this.actionButton.classList.add("disabled");
      return;
    }

    this.actionButton.classList.toggle("selected", readStatus === "plan_to_read");
    this.iconElement.textContent = readStatus === "plan_to_read" ? "bookmark_added" : "bookmark_add";
  }

  toggleSelectedState() {
    const isSelected = this.actionButton.classList.toggle("selected");
    this.iconElement.textContent = isSelected ? "bookmark_added" : "bookmark_add";
    return isSelected;
  }


  redirectToLoginOnClick() {
    this.planToReadButton.addEventListener("click", () => {
      const uri = encodeURI(location.pathname + location.search + location.hash);
      location.href = `/login?rt=${uri}`;
    });
  }

  addClickListener(callback) {
    this.planToReadButton.addEventListener("click", callback);
  }

  broadcastStateChange(newStatus, boardId) {
    const allModules = document.querySelectorAll(".topic-plan-to-read-button-module");
    allModules.forEach(module => {
      const boardIdMatch = module.querySelector(".topic-plan-to-read-button").dataset.boardId === boardId;
      if (boardIdMatch) {
        new TopicPlanToReadButtonUI(module).updateUI(newStatus);
      }
    });
  }

  getReadStateText(readStatus) {
    const statusMap = {
      "plan_to_read": "読みたい",
      "on_hold": "積読",
      "reading": "読んでる",
      "completed": "読んだ",
    };
    return statusMap[readStatus] || "読みたい";
  }
}

// 初期化関数
const initTopicPlanToReadModule = (modules) => {
  new TopicPlanToReadButtonModule(modules);
};

// イベントリスナー
document.addEventListener("DOMContentLoaded", () => {
  const moduleClassName = ".topic-plan-to-read-button-module";

  pubsubStore.subscribe("userLoginCompleted", () => {
    const modules = document.querySelectorAll(moduleClassName);
    if (modules.length) initTopicPlanToReadModule(modules);
  });

  pubsubStore.subscribe("renderAsyncLoad", (eventContainer) => {
    const modules = eventContainer.querySelectorAll(moduleClassName);
    if (modules.length) initTopicPlanToReadModule(modules);
  });
});
