import Api from "../../Api";
import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

class FreeSpacesTopicModule {
  constructor(modules) {
    this.modules = modules

    if (!this.modules.length) return;

    this.setupPageShowListener();
    this.initState();
  }

  setupPageShowListener() {
    const reloadIfNeeded = (event) => {
      if (event.persisted || this.isBackForwardNavigation()) {
        window.location.reload();
      }
    };
    window.addEventListener('pageshow', reloadIfNeeded, { once: true });
  }

  isBackForwardNavigation() {
    const [navigationEntry] = performance.getEntriesByType("navigation");
    return navigationEntry?.type === "back_forward";
  }

  async initState() {
    for (const element of this.modules) {
      const topicId = element.dataset.topicId;
      if (topicId) {
        await this.updateTopicStatus(element, topicId);
      }
    }
  }

  async updateTopicStatus(element, topicId) {
    try {
      const { unread, last_updated_changed } = await Api.topicStatus(topicId);
      const lastUpdateAtChangedElement = element.querySelector(".last-updated-at-changed");

      if (!unread && last_updated_changed) {
        lastUpdateAtChangedElement.style.display = "block";
      } else {
        lastUpdateAtChangedElement?.remove();
      }
    } catch (error) {
      console.error("Error updating topic status:", error);
    }
  }
}

const initFreeSpacesModule = (modules) => {
  if (loginStateStore.isLoggedIn()) {
    new FreeSpacesTopicModule(modules);
  }
};

document.addEventListener("DOMContentLoaded", function (event) {
  pubsubStore.subscribe("userLoginCompleted", args => {
    let modules = document.querySelectorAll(".free-spaces-topic-main-module");
    if (!modules.length) return;
    initFreeSpacesModule(modules)
  });

  pubsubStore.subscribe("renderAsyncLoad", eventContainer => {
    let modules = eventContainer.querySelectorAll(".free-spaces-topic-main-module")
    if (!modules.length) return;
    initFreeSpacesModule(modules)
  });
});
