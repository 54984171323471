document.addEventListener("DOMContentLoaded", function (event) {
  if (!app.inLayout("application-redirects")) {
    return;
  }

  const redirectElement = document.querySelector("#redirect_url a");

  if (redirectElement) {
    const redirectUrl = redirectElement.href;
    //setTimeout(() => {
    window.location.href = redirectUrl;
    //}, 5000);
  }

});
