document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let commentMetadataElement = document.querySelector(".comment-metadata");

  if (commentMetadataElement === null) {
    return;
  }

  let comments = document.querySelectorAll(".comment-metadata");

  comments.forEach(element => {
    let commentEmpathyElement = element.querySelector(".comment-empathy");
    let postEmpathyElement = element.querySelector(".post-empathy");

    if (postEmpathyElement === null) {
      return;
    }

    postEmpathyElement.addEventListener("click", function () {
      let commentId = postEmpathyElement.dataset.commentId;

      // 画像の拡大
      let imageElement = postEmpathyElement.querySelector(
        ".material-icons-outlined"
      );
      imageElement.classList.add("animate");

      setTimeout(() => {
        imageElement.classList.remove("animate");
      }, 1000);

      if (window.navigator.vibrate !== undefined) {
        window.navigator.vibrate(50);
      }

      commentEmpathyElement.classList.remove("not-empathy");

      let empathyCountElement = commentEmpathyElement.querySelector(".count");
      empathyCountElement.innerHTML =
        parseInt(empathyCountElement.innerHTML) + 1;

      let url = "/api/comments/" + commentId + "/empathies";

      return fetch(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => { });
    });
  });
});
