document.addEventListener("DOMContentLoaded", function (event) {
	"use strict";

	let module = document.getElementById('users-notification-select-box')

	if (module === null) {
		return;
	}

	document.getElementById('users-notification-select-box').addEventListener('change', function () {
		// 現在のページのパスを取得
		const currentPagePath = window.location.pathname;

		// 追加したいURLパラメータ
		const params = {
			selected_type: this.value
		};

		// URLパラメータを文字列に変換
		const queryString = Object.keys(params).map(key =>
			`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
		).join('&');

		// パスとクエリ文字列を組み合わせる
		const newUrl = `${currentPagePath}?${queryString}`;

		window.location.href = newUrl;

	});
});
