class QuicklinkHelper {
  constructor() {
    this.options = null;
    this.quicklink_reset_func = null;
  }

  setOptions(options) {
    this.options = options;
  }
  
  listen() {
    this.reset()
    this.quicklink_reset_func = quicklink.listen(this.options);
  }

  prefetch(url) {
    quicklink.prefetch(url);
  }

  reset() {
    if (this.quicklink_reset_func !== null) {
      this.quicklink_reset_func();
    }
  }
}

const quicklinkHelperInstance = new QuicklinkHelper();
export default quicklinkHelperInstance;
