import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let modules = document.querySelectorAll(".follow-user-button-module");

  if (modules.length == 0) {
    return;
  }

  let initFollowAction = element => {
    let key = loginStateStore.getKey();
    let userKey = element.dataset.userKey;
    let followActionElement = element.querySelector(".follow-action");
    let textElement = element.querySelector(".follow-action .text");

    if (followActionElement == null) {
      return;
    }

    if (key === userKey) {
      followActionElement.remove();
      return;
    }

    if (loginStateStore.isLoggedIn() == true) {
      Api.userFollowing(userKey).then(json => {
        if (json.following) {
          followActionElement.classList.add("selected");
          textElement.innerText = "フォロー中";
        }
        followActionElement.style.display = 'block';
      });
    } else {
      followActionElement.style.display = 'block';
    }

    followActionElement.addEventListener("click", function () {
      if (loginStateStore.isLoggedIn() == false) {
        const uri = encodeURI(location.pathname + location.search + location.hash);
        location.href = '/login?rt=' + uri;
        return;
      }

      if (followActionElement.classList.contains("selected") == true) {
        followActionElement.classList.remove("selected");
        Api.removeUserFollow(userKey)
        textElement.innerText = "フォローする";
      } else {
        followActionElement.classList.add("selected");
        Api.addUserFollow(userKey)
        textElement.innerText = "フォロー中";
      }
    });
  };

  let initState = () => {
    modules.forEach(element => {
      initFollowAction(element);
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appentRootElement => {
    appentRootElement.querySelectorAll(".follow-user-button-module").forEach(element => {
      initFollowAction(element);
    });
  });

});
