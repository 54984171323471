import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

class ImportLinkVideoModule {
  constructor() {
    this.player = null;
    this.modules = document.querySelectorAll(".import-video-link-module");
  }

  initializeModules() {
    this.loadYouTubeAPI();

    this.modules.forEach(element => {
      this.initDialog(element);
    });
  }

  loadYouTubeAPI() {
    const script = document.createElement('script');
    script.src = "//www.youtube.com/iframe_api";
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);
  }

  initDialog(element) {
    const newImportLinkVideoLink = element.querySelector(".import-video-link-contents");
    const dialogElement = element.querySelector(".import-video-link-dialog");
    const closeButton = dialogElement.querySelector(".close-button");

    let player = null;

    const createYouTubePlayer = (embedPlayer, videoId) => {
      if (player) {
        return player;
      }

      const { width, height } = this.getPlayerDimensions();

      player = new YT.Player(embedPlayer, {
        height: height,
        width: width,
        videoId: videoId,
        events: {
          'onReady': this.onPlayerReady
        }
      });

      return player;
    };

    newImportLinkVideoLink.addEventListener('click', () => {
      const dialogFormContentsElement = element.querySelector(".dialog-form-contents");
      const videoUrl = dialogFormContentsElement.dataset.videoUrl;
      const videoId = new URL(videoUrl).searchParams.get("v");

      const embedPlayer = dialogFormContentsElement.querySelector('.embed-player');
      createYouTubePlayer(embedPlayer, videoId);

      dialogElement.showModal();
    });

    closeButton.addEventListener('click', () => {
      dialogElement.close();
      this.pauseVideo(player);
    });

    dialogElement.addEventListener('click', (event) => {
      if (!event.target.closest('.import-video-link-dialog-contents')) {
        dialogElement.close();
        this.pauseVideo(player);
      }
    });
  }

  getPlayerDimensions() {
    if (app.isSmartPhone()) {
      return { width: 320, height: 180 };
    }
    return { width: 640, height: 360 };
  }

  onPlayerReady(event) {
    console.log(event);
  }

  pauseVideo(player) {
    if (player && player.pauseVideo) {
      player.pauseVideo();
    }
  }
}

const importLinkVideoModuleInstance = new ImportLinkVideoModule();
export default importLinkVideoModuleInstance;

document.addEventListener("DOMContentLoaded", () => {
  importLinkVideoModuleInstance.initializeModules();
});
