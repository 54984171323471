import "whatwg-fetch";

export default class API {
  static csrfToken() {
    return fetch("/api/users/csrf_token", {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static currentUserInfo() {
    return fetch("/api/users/current_user_info", {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static addUserRecentVisitedTopicLogs(topic_id) {
    let body = JSON.stringify({ topic_id: topic_id });

    return fetch("/api/users/recent_visited_topics", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }).then(response => {
      return response.json();
    });
  }

  static addUserRecentVisitedBoardLogs(board_id) {
    let body = JSON.stringify({ board_id: board_id });

    return fetch("/api/users/visited_boards", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }).then(response => {
      return response.json();
    });
  }

  static addUserRecentVisitedAuthorLogs(author_id) {
    let body = JSON.stringify({ author_id: author_id });

    return fetch("/api/users/visited_authors", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }).then(response => {
      return response.json();
    });
  }


  static userLatestUsedNameInTopic(topic_id) {
    let url = "/api/users/topics/latest_used_name?topic_id=" + topic_id;

    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static boardReadStatus(board_id) {
    let url = "/api/users/boards/" + board_id + "/read_status";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static updateReadStatus(board_id, readStatus) {
    let url = "/api/users/boards/" + board_id + "/read_status";
    let body = JSON.stringify({ read_status: readStatus });

    return fetch(url, {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }).then(response => {
      return response.json();
    });
  }

  static updateReadStatusPlanToReadFromTopic(board_id, topic_id) {
    let url = "/api/users/boards/" + board_id + "/read_status/plan_to_read_from_topic";
    let body = JSON.stringify({
      topic_id: topic_id,
    });

    return fetch(url, {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }).then(response => {
      return response.json();
    });
  }

  static updateReadStatusPlanToReadFromListItem(board_id, list_item_id) {
    let url = "/api/users/boards/" + board_id + "/read_status/plan_to_read_from_list_item";
    let body = JSON.stringify({
      list_item_id: list_item_id,
    });

    return fetch(url, {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }).then(response => {
      return response.json();
    });
  }

  static boardFavoriteRating(board_id) {
    let url = "/api/users/boards/" + board_id + "/favorite_rating";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static userBoardFavoriteRating(userKey, board_id) {
    let url = "/api/users/" + userKey + "/boards/" + board_id + "/favorite_rating";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static userBoardReviewed(userKey, board_id) {
    let url = "/api/users/" + userKey + "/boards/" + board_id + "/reviewed";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static updateFavoriteRating(board_id, favoriteRating, publicStatus) {
    let url = "/api/users/boards/" + board_id + "/favorite_rating";
    let body = JSON.stringify({
      favorite_rating: favoriteRating,
      public_status: publicStatus
    });

    return fetch(url, {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }).then(response => {
      return response.json();
    });
  }

  static boardReadingNote(board_id) {
    let url = "/api/users/boards/" + board_id + "/reading_note";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static updateReadingNote(board_id, readingNoteMemo) {
    let url = "/api/users/boards/" + board_id + "/reading_note";
    let body = JSON.stringify({ memo: readingNoteMemo });

    return fetch(url, {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
        'Content-Type': 'application/json'
      },
      body: body
    }).then(response => {
      return response.json();
    });
  }


  static boardRereading(board_id) {
    let url = "/api/users/boards/" + board_id + "/reread";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static addBoardReread(board_id) {
    let url = "/api/users/boards/" + board_id + "/reread";
    return fetch(url, {
      method: "POST",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static removeBoardReread(board_id) {
    let url = "/api/users/boards/" + board_id + "/reread";
    return fetch(url, {
      method: "DELETE",
      credentials: "same-origin"
    });
  }

  static topicViewCounter(topic_id) {
    let url = "/api/topics/" + topic_id + "/view_counter";
    return fetch(url, {
      method: "PATCH",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static userBoardListViewCounter(user_board_list_id) {
    let url = "/api/user_board_lists/" + user_board_list_id + "/view_counter";
    return fetch(url, {
      method: "PATCH",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static async themeViewCounter(theme_id) {
    let url = "/api/themes/" + theme_id + "/view_counter";
    const response = await fetch(url, {
      method: "PATCH",
      credentials: "same-origin"
    });
    return await response.json();
  }


  static authorViewCounter(author_id) {
    let url = "/api/authors/" + author_id + "/view_counter";
    return fetch(url, {
      method: "PATCH",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static boardViewCounter(board_id) {
    let url = "/api/boards/" + board_id + "/view_counter";
    return fetch(url, {
      method: "PATCH",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static userFollowing(key) {
    let url = "/api/users/" + key + "/follow";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static addUserFollow(key) {
    let url = "/api/users/" + key + "/follow";
    return fetch(url, {
      method: "POST",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static removeUserFollow(key) {
    let url = "/api/users/" + key + "/follow";
    return fetch(url, {
      method: "DELETE",
      credentials: "same-origin"
    });
  }

  static boardFollowing(board_id) {
    let url = "/api/users/boards/" + board_id + "/follow";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static addBoardFollow(board_id) {
    let url = "/api/users/boards/" + board_id + "/follow";
    return fetch(url, {
      method: "POST",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static removeBoardFollow(board_id) {
    let url = "/api/users/boards/" + board_id + "/follow";
    return fetch(url, {
      method: "DELETE",
      credentials: "same-origin"
    });
  }

  static authorFollowing(author_id) {
    let url = "/api/users/authors/" + author_id + "/follow";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static addAuthorFollow(author_id) {
    let url = "/api/users/authors/" + author_id + "/follow";
    return fetch(url, {
      method: "POST",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static removeAuthorFollow(author_id) {
    let url = "/api/users/authors/" + author_id + "/follow";
    return fetch(url, {
      method: "DELETE",
      credentials: "same-origin"
    });
  }

  static topicStatus(topic_id) {
    let url = "/api/users/topics/" + topic_id + "/status";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static topicFollowing(topic_id) {
    let url = "/api/users/topics/" + topic_id + "/follow";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static addTopicFollow(topic_id) {
    let url = "/api/users/topics/" + topic_id + "/follow";
    return fetch(url, {
      method: "POST",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static removeTopicFollow(topic_id) {
    let url = "/api/users/topics/" + topic_id + "/follow";
    return fetch(url, {
      method: "DELETE",
      credentials: "same-origin"
    });
  }

  static userTopicOwned(topic_id) {
    let url = "/api/users/topics/" + topic_id + "/owned";
    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }

  static async commentSelfReply(comment_id) {
    let url = "/api/comments/" + comment_id + "/self_replies";
    const response = await fetch(url, {
      method: "GET",
      credentials: "same-origin"
    });
    return await response.json();
  }


  static async commentRateLimit() {
    let url = "/api/comments/rate_limits";
    const response = await fetch(url, {
      method: "GET",
      credentials: "same-origin"
    });
    return await response.json();
  }

  static userTopicCommentsEditable(topic_id, comment_ids) {
    let url = "/api/users/topics/" + topic_id + "/comments/editable";
    let i = 0;
    let query = "?";
    let key = "comment_ids";

    for (i = 0; i < comment_ids.length; i++) {
      query += key + "[]";
      query += "=";
      query += comment_ids[i];
      query += "&";
    }
    query = query.substr(0, query.length - 1);

    return fetch(url + query, {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      return response.json();
    });
  }
}
