import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";
  let modules = document.querySelectorAll(".follow-topic-module");

  if (modules.length == 0) {
    return;
  }

  let updateFollowButtonState = element => {
    let followButton = element.querySelector(".follow-button");
    let loggedInFollowButton = element.querySelector(".follow-button.logged-in");
    let notLoggedInFollowButton = element.querySelector(".follow-button.not-logged-in");
    let topicId = element.dataset.topicId;

    if (loginStateStore.isLoggedIn() == false) {
      notLoggedInFollowButton.style.visibility = "visible";
      loggedInFollowButton.remove();
      return;
    }

    notLoggedInFollowButton.remove();
    Api.topicFollowing(topicId).then(json => {
      loggedInFollowButton.style.visibility = "visible";

      if (json.following) {
        loggedInFollowButton.innerText = "フォロー中";
        loggedInFollowButton.classList.add("selected");
      } else {
        loggedInFollowButton.innerText = "クチコミをフォローする";
        loggedInFollowButton.classList.remove("selected");
      }
    });
  };

  let initFollowButtonState = element => {
    let topicId = element.dataset.topicId;
    let followButton = element.querySelector(".follow-button");

    if (loginStateStore.isLoggedIn() == true) {
      Api.topicFollowing(topicId).then(json => {
        followButton.style.visibility = "visible";

        if (json.following) {
          followButton.innerText = "フォロー中";
          followButton.classList.add("selected");
        } else {
          followButton.innerText = "クチコミをフォローする";
          followButton.classList.remove("selected");
        }
      });
    } else {
      followButton.style.visibility = "visible";

    }

    followButton.addEventListener("click", function () {
      if (loginStateStore.isLoggedIn() == false) {
        const uri = encodeURI(location.pathname + location.search + location.hash);
        location.href = '/login?rt=' + uri;
        return;
      }

      if (followButton.classList.contains("selected") == true) {
        followButton.classList.remove("selected");
        followButton.innerText = "フォローする";

        Api.removeTopicFollow(topicId);
      } else {
        followButton.innerText = "フォロー中";
        followButton.classList.add("selected");

        Api.addTopicFollow(topicId);
      }
    });
  }

  let initState = () => {
    modules.forEach(element => {
      initFollowButtonState(element)
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appendRootElement => {
    appendRootElement.querySelectorAll(".follow-topic-module").forEach(element => {
      initFollowButtonState(element)
    });
  });
});
