import Api from "../../Api";
import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  const modules = document.querySelectorAll(".authors-follow-button-module");

  if (modules.length == 0) {
    return;
  }

  let updateFollowButtonState = element => {
    let authorId = element.dataset.authorId;

    if (loginStateStore.isLoggedIn() == false) {
      return;
    }

    Api.authorFollowing(authorId).then(json => {

      let actionButton = element.querySelector(".action-button");
      let actionIconElement = element.querySelector(".icon .material-icons-outlined");
      let actionButtonText = element.querySelector(".text");

      if (json.following) {
        actionButtonText.innerText = "フォロー中";
        actionIconElement.textContent = "notifications";
        actionButton.classList.add("selected");
      }
    });
  };

  let initFollowButtonState = () => {
    modules.forEach(element => {
      updateFollowButtonState(element);
      let authorId = element.dataset.authorId;

      let actionButton = element.querySelector(".action-button");
      let actionIconElement = element.querySelector(".icon .material-icons-outlined");
      let actionButtonText = element.querySelector(".text");
      let message = element.querySelector(".message");

      actionButton.addEventListener("click", function () {
        if (loginStateStore.isLoggedIn() == false) {
          const uri = encodeURI(location.pathname + location.search + location.hash);
          location.href = '/login?rt=' + uri;
          return;
        }

        if (actionButton.classList.contains("selected") == true) {
          Api.removeAuthorFollow(authorId).then(json => { });

          actionButton.classList.remove("selected");
          actionButtonText.innerText = "著者をフォローする";
          actionIconElement.textContent = "notifications";

          message.style.display = "none";
        } else {
          Api.addAuthorFollow(authorId).then(json => { });
          actionButton.classList.add("selected");
          actionButtonText.innerText = "フォロー中";
          actionIconElement.textContent = "notifications";

          message.style.display = "block";
        }
      });
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initFollowButtonState();
  });
});
