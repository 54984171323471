import loginStateStore from "../../login_state_store";
import pubsubStore from "../../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
    "use strict";

    let module = document.querySelector(".boards-action-announce-module");
    if (module == null) {
        return;
    }

    pubsubStore.subscribe("userLoginCompleted", args => {

        if (loginStateStore.isLoggedIn() === true) {
            module.style.display = "block";
            let openButtonElement = module.querySelector(".floting-menu-open-action");

            openButtonElement.addEventListener("click", function () {
                let floatingMenuButtonElement = document.querySelector(".floating-menu-button");
                floatingMenuButtonElement.click();
            });
        }
    });

});
