// https://yuku.takahashi.coffee/textcomplete/
// https://github.com/yuku/textcomplete

import algoliasearchApiInstance from "./api";

document.addEventListener("DOMContentLoaded", function (event) {
  let myTextareaElements = document.querySelectorAll(
    ".textarea-autocomplete-enabled"
  );
  if (myTextareaElements.length == 0) {
    return;
  }

  const { Textcomplete } = require("@textcomplete/core");
  const { TextareaEditor } = require("@textcomplete/textarea");

  let boardIndex = algoliasearchApiInstance.getBoardIndex();
  let authorIndex = algoliasearchApiInstance.getAuthorIndex();

  let searchBoardHits = term => {
    return new Promise(resolve => {
      let filters = {
        filters: "is_suggestable: true",
      };
      boardIndex.search(term, filters).then(({ hits }) =>
        resolve(
          hits.map(function (item) {
            return [item.title, item.suggest_thumbnail_url];
          })
        )
      );
    });
  };

  let searchAuthorHits = term => {
    return new Promise(resolve => {
      let filters = {
        filters: "is_suggestable: true",
      };
      authorIndex.search(term, filters).then(({ hits }) =>
        resolve(
          hits.map(function (item) {
            return [item.name, item.thumbnail_url];
          })
        )
      );
    });
  };

  let strategyBoard = {
    //match: /\/([\-+\w].*)$/,
    match: /\/(.+)$/,
    search: async (term, callback) => {
      callback(await searchBoardHits(term));
    },
    template: ([key, url]) => `<div class="board-text-item"><img src="${url}"/><div class='text'>${key}</div></div>`,
    replace: result => `${result[0]}`,
    cache: true
  };

  let strategyAuthor = {
    //match: /\@([\-+\.]*)$/,
    match: /\@(.+)$/,
    search: async (term, callback) => {
      callback(await searchAuthorHits(term));
    },
    template: ([key, url]) => `<div class="author-text-item"><img src="${url}"/><div class='text'>${key}</div></div>`,
    replace: result => `${result[0]}`,
    cache: true
  };

  // Default option. All properties are optional recursively.
  let option = {
    // Configure a dropdown UI.
    dropdown: {
      // Class attribute of the dropdown element.
      className: "dropdown-menu textcomplete-dropdown",

      // The maximum number of items to be rendered.
      maxCount: 5,

      // Placement of the dropdown. "auto", "top" or "bottom".
      placement: "auto",

      // Return header and footer elements' content

      // Whether activate the opposite side item on pressing up or
      // down key when an edge item is active.
      rotate: false,

      // Configure CSS style of the dropdown element.
      style: { display: "none", position: "absolute", zIndex: "1000" },

      // The parent node of the dropdown element.
      parent: document.body,

      item: {
        // Class attribute of the each dropdown item element.
        className: "textcomplete-item",

        // Active item's class attribute.
        activeClassName: "textcomplete-item active"
      }
    }
  };
  myTextareaElements.forEach(element => {
    const editor = new TextareaEditor(element);
    const textcomplete = new Textcomplete(
      editor,
      [strategyBoard, strategyAuthor],
      option
    );
  });
});
