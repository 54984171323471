import algoliasearchApiInstance from "../../algoliasearch/api";

let enableAutoComplete = (searchInputId, searchInputName) => {
  const { autocomplete, getAlgoliaResults } = window[
    "@algolia/autocomplete-js"
  ];
  const searchClient = algoliasearchApiInstance.getClient();
  const boardIndexName = algoliasearchApiInstance.getBoardIndexName();

  const autocompleteSearch = autocomplete({
    container: searchInputId,
    detachedMediaQuery: "none",
    placeholder: '作品・著者・キーワードで探す',
    id: searchInputName + "-autocomplete",
    getSources() {
      return [
        {
          sourceId: "boards",
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: boardIndexName,
                  query,
                  params: {
                    filters: "is_suggestable: true",
                    hitsPerPage: 5
                  }
                }
              ]
            });
          },
          templates: {
            item({ item, components, html }) {
              return html`
                <div class="aa-manba-title-item">
                    <img src=${item.suggest_thumbnail_url} alt='${item.title}' />
                    <div>
                      ${components.Highlight({
                hit: item,
                attribute: "title",
                tagName: "mark"
              })}
                    </div>
                  </div>
              `;
            }
          },
          onSelect({ insights, insightsEvents, item }) {
            if (item.redirect_url !== undefined) {
              window.location.href = item.redirect_url
            }
          }
        },
      ];
    }
  });
}

let keypressSearch = (searchInput) => {
  let targetName = "#" + searchInput + "-autocomplete-input";
  let element = document.querySelector(targetName);

  if (element === null) {
    return;
  }

  element.addEventListener("keypress", function (event) {
    let value = element.value;
    if (value !== "" && event.key === "Enter") {
      event.preventDefault();
      let redirectUrl = `/search?q=${element.value}`;
      location.href = redirectUrl;
    }
  });
}

let buttonSearch = (searchNameId, searchNameButtonId) => {

  let targetName = searchNameId + "-autocomplete-input";
  let elementInput = document.querySelector(targetName);
  let elementButton = document.querySelector(searchNameButtonId);

  elementButton.addEventListener("click", function (event) {
    event.preventDefault();

    let value = elementInput.value;

    if (value !== "") {
      let redirectUrl = `/search?q=${elementInput.value}`;
      location.href = redirectUrl;
    }
  });
}

document.addEventListener("DOMContentLoaded", function (event) {
  const searchName = "search-box-with-autocomplete-search";
  const searchNameId = "#" + searchName;
  if (document.querySelector(searchNameId) === null) {
    return
  }

  const searchNameButton = "search-box-with-autocomplete-search-button";
  const searchNameButtonId = "#" + searchNameButton;

  enableAutoComplete(searchNameId, searchName);
  keypressSearch(searchName);
  buttonSearch(searchNameId, searchNameButtonId);

});
