import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";
import csrfTokenStore from "../csrf_token_store";
import quicklinkHelperInstance from "../quicklink_helper";

let app = {
  inController: function (name) {
    let target_name = "html." + name.replace(/\//g, "-") + "-controller";
    return document.querySelectorAll(target_name).length > 0;
  },
  inActon: function (name) {
    let target_name = "html." + name + "-action";
    return document.querySelectorAll(target_name).length > 0;
  },
  inLayout: function (name) {
    let target_name = "html." + name + "-layout";
    return document.querySelectorAll(target_name).length > 0;
  },
  isSmartPhone: function () {
    let w = parseFloat(
      window.getComputedStyle(document.body, null).width.replace("px", "")
    );
    if (w < 1200) {
      return true;
    }
    return false;
  },
  headerHeight: function () {
    return this.isSmartPhone() ? 55 : 70;
  },
  scrollToHash: function (targetId) {
    const targetElement = document.querySelector(targetId);
    const targetOffsetTop =
      window.pageYOffset +
      targetElement.getBoundingClientRect().top -
      this.headerHeight();
    window.scrollTo({
      top: targetOffsetTop,
      behavior: "auto"
    });
  }
};

window.app = app;

Api.currentUserInfo().then(json => {
  loginStateStore.setState(json);
  pubsubStore.publish("userLoginCompleted", loginStateStore.get());
});

Api.csrfToken().then(json => {
  csrfTokenStore.setState(json.csrf_param, json.csrf_token);
  pubsubStore.publish("csrfTokenCompleted", csrfTokenStore.get());
});

document.addEventListener("DOMContentLoaded", function (event) {
  let elements = document.querySelectorAll("form");
  if (elements.length === 0) {
    return;
  }
  elements.forEach(element => {
    element.addEventListener("submit", function (event) {
      let submitButton = element.querySelector('button[type="submit"]');
      submitButton.disabled = true;
    });
  });
});

document.addEventListener("DOMContentLoaded", function (event) {
  let elements = document.getElementsByClassName("glightbox");
  if (elements.length === 0) {
    return;
  }

  const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true
  });
});

document.addEventListener("DOMContentLoaded", function (event) {
  let elements = document.getElementsByClassName("application-layout");
  if (elements.length === 0) {
    return;
  }

  const quicklinkEnabledElement = document.querySelector('meta[name="quicklink_enabled"]')
  let quicklinkEnabled = false

  if (quicklinkEnabledElement !== null) {
    quicklinkEnabled = quicklinkEnabledElement.content === 'true' ? true : false
  }

  const quicklinkThrottleElement = document.querySelector('meta[name="quicklink_throttle"]')
  let quicklinkThrottle = 1

  if (quicklinkThrottleElement !== null) {
    quicklinkThrottle = quicklinkThrottleElement.content !== '' ? parseInt(quicklinkThrottleElement.content) : 1
  }

  const quicklinkListenEnabledElement = document.querySelector('meta[name="quicklink_listen_enabled"]')
  let quicklinkListenEnabled = false

  if (quicklinkListenEnabledElement !== null) {
    quicklinkListenEnabled = quicklinkListenEnabledElement.content === 'true' ? true : false
  }

  if (quicklinkEnabled == true) {
    const options = {
      throttle: quicklinkThrottle,
      ignores: [
        /\/me\/?/,
        /\/admin\/?/,
        /\/login\/?/,
        /\/logout\/?/,
        /\/auth\/twitter/,
        /\/auth\/facebook/,
        /\/auth\/line/,
        /\/auth\/google_oauth2/,
        /\/auth\/yahoojp/,
        /\/stores\/?/,
        /\/previews\/?/,
        /\/import_links\/?/,
        /\/search\/?/,
        /\/advanced_search\/?/,
        /\/replies\/?/,
        /\/notifications\/?/,
        /\/filter_search\/?/,
        /\/free_campaigns\/?/,
        /\/web_manga\/redirect\/?/,
        (uri, elem) => {
          let res = false
          if (uri.includes('#')) {
            res = true
          }
          if (elem.rel.includes('noprefetch')) {
            res = true
          }
          if (elem.classList.contains("manba-quicklink-prefetch")) {
            res = true
          }
          return res
        }
      ]
    };

    if (quicklinkListenEnabled == true) {
      quicklinkHelperInstance.setOptions(options);
      quicklinkHelperInstance.listen();
    }

    const nextPageElement = document.head.querySelector('link[rel="next"]')
    if (nextPageElement != null) {
      quicklinkHelperInstance.prefetch(nextPageElement.href);
    }
    const prevPageElement = document.head.querySelector('link[rel="prev"]')
    if (prevPageElement != null) {
      quicklinkHelperInstance.prefetch(prevPageElement.href);
    }

    const prefetchElements = document.querySelectorAll('a.manba-quicklink-prefetch')
    prefetchElements.forEach(element => {
      quicklinkHelperInstance.prefetch(element.href);
    });
  }
});

pubsubStore.subscribe("userLoginCompleted", args => {

  return;
  if (loginStateStore.isLoggedIn() == true) {
    console.log("ログイン済みなので広告を出さない");
    return;
  }
  function loadGoogleAdSense() {
    // GoogleAdSense読込み
    const ad = document.createElement("script");
    ad.type = "text/javascript";
    ad.async = true;
    ad.crossorigin = 'anonymous';
    ad.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3438061032850215";
    const sc = document.getElementsByTagName("script")[0];
    sc.parentNode.insertBefore(ad, sc);
  }

  // 遅延読込み
  let lazyLoad = false;
  function onLazyLoad() {
    if (lazyLoad === false) {
      // 複数呼び出し回避 + イベント解除
      lazyLoad = true;
      window.removeEventListener('scroll', onLazyLoad);
      window.removeEventListener('mousemove', onLazyLoad);
      window.removeEventListener('mousedown', onLazyLoad);
      window.removeEventListener('touchstart', onLazyLoad);
      window.removeEventListener('keydown', onLazyLoad);

      loadGoogleAdSense();
    }
  }
  window.addEventListener("scroll", onLazyLoad, { passive: true });
  window.addEventListener('mousemove', onLazyLoad, { passive: true });
  window.addEventListener('mousedown', onLazyLoad, { passive: true });
  window.addEventListener('touchstart', onLazyLoad, { passive: true });
  window.addEventListener('keydown', onLazyLoad, { passive: true });
  window.addEventListener("load", function () {
    // ドキュメント途中（更新時 or ページ内リンク）
    if (window.scrollY > 0) {
      onLazyLoad();
    }
  });

  let applicationElement = document.querySelector(".application-layout");
  window.setTimeout(onLazyLoad, applicationElement ? 3000 : 0);

});

document.addEventListener("DOMContentLoaded", function (event) {
  let setCsrfMetaTag = () => {
    let csrfParamElement = document.querySelector("[name=csrf-param]");
    let csrfTokenElement = document.querySelector("[name=csrf-token]");
    csrfParamElement.content = csrfTokenStore.getCsrfParam();
    csrfTokenElement.content = csrfTokenStore.getCsrfToken();
  };

  pubsubStore.subscribe("csrfTokenCompleted", args => {
    setCsrfMetaTag();
  });

});

document.addEventListener('render_async_load', function (event) {
  pubsubStore.publish("renderAsyncLoad", event.container);
});
