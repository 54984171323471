class AlgoliasearchApi {
  constructor() {
    this.client = algoliasearch(
      "USNQ53S104",
      "6a09fe0f2dcfb9547d7c6141100661ad"
    );
    this.boardIndex = undefined;
    this.authorIndex = undefined;
    this.topicIndex = undefined;
    this.manbaMagazineArticleIndex = undefined;
  }

  getClient() {
    if (this.client === undefined) {
      this.client = algoliasearch(
        "USNQ53S104",
        "6a09fe0f2dcfb9547d7c6141100661ad"
      );
    }

    return this.client;
  }

  getBoardIndexName() {
    return "Board_production";
  }

  getAuthorIndexName() {
    return "Author_production";
  }

  getTopicIndexName() {
    return "Topic_production";
  }

  getUserBoardListIndexName() {
    return "UserBoardlist_production";
  }


  getManbaMagazineArticleIndexName() {
    return "ManbaMagazineArticle_production";
  }

  getBoardIndex() {
    if (this.boardIndex === undefined) {
      this.boardIndex = this.getClient().initIndex("Board_production");
    }
    return this.boardIndex;
  }

  getAuthorIndex() {
    if (this.authorIndex === undefined) {
      this.authorIndex = this.getClient().initIndex("Author_production");
    }
    return this.authorIndex;
  }

  getTopicIndex() {
    if (this.topicIndex === undefined) {
      this.topicIndex = this.getClient().initIndex("Topic_production");
    }
    return this.topicIndex;
  }

  getManbaMagazineArticleIndex() {
    if (this.manbaMagazineArticleIndex === undefined) {
      this.manbaMagazineArticleIndex = this.getClient().initIndex(
        "ManbaMagazineArticle_production"
      );
    }
    return this.manbaMagazineArticleIndex;
  }
}

const algoliasearchApiInstance = new AlgoliasearchApi();
export default algoliasearchApiInstance;
