import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let modules = document.querySelectorAll(".reread-board-action-icon-module");

  if (modules.length == 0) {
    return;
  }

  let updateRereadActionState = element => {
    let boardId = element.dataset.boardId
    let rereadAction = element.querySelector(".reread-action");

    if (loginStateStore.isLoggedIn() == false) {
      return;
    }

    Api.boardRereading(boardId).then(json => {
      if (json.rereading) {
        rereadAction.classList.add("selected");
      }
    });
  };

  let initRereadAction = element => {
    let boardId = element.dataset.boardId
    let rereadAction = element.querySelector(".reread-action");

    rereadAction.addEventListener("click", function () {
      if (loginStateStore.isLoggedIn() == false) {
        const uri = encodeURI(location.pathname + location.search + location.hash);
        location.href = '/login?rt=' + uri;
        return;
      }

      if (rereadAction.classList.contains("selected") == true) {
        rereadAction.classList.remove("selected");
        Api.removeBoardReread(boardId);
      } else {
        rereadAction.classList.add("selected");
        Api.addBoardReread(boardId);
      }
    });
  };

  let initState = () => {
    modules.forEach(element => {
      initRereadAction(element);
      updateRereadActionState(element);
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appentRootElement => {
    appentRootElement.querySelectorAll(".reread-board-action-icon-module").forEach(element => {
      initRereadAction(element);
      updateRereadActionState(element);
    });
  });
});
