import csrfTokenStoreInstance from "../../csrf_token_store";
import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let module = document.querySelector(".topic-input-form");

  if (module == null) {
    return;
  }

  let boardTitle = document.querySelector("h1.board-books-main-title a")
    .textContent;

  const questionMessages = () => {
    return [
      {
        value: "質問1",
        text: "作品の疑問点について質問する",
        title: "作品の疑問点について質問があります",
        body: "わからなかった部分について質問してみましょう。\n" +
          "\n" +
          "「話のわからないところがあって…」\n" +
          "「あのオチってどういうこと？」\n" +
          "「ラストシーンのみんなの解釈を聞きたい」"
      },
      {
        value: "質問2",
        text: "書籍に関して質問する",
        title: "書籍について教えてください",
        body: "書籍情報について質問してみましょう。\n" +
          "\n" +
          "「紙書籍と電子書籍どちらを買うべきでしょうか」\n" +
          "「◯◯の話が収録されているか教えて下さい」\n" +
          "「新装版と完全版は何が違うのでしょうか」\n" +
          "「特典はついてきますか」"
      }
    ]
  }

  const chatMessages = () => {
    return [
      {
        value: "雑談1",
        text: "連載/新連載作品の雑談",
        title: "作品のキャッチコピー",
        body: "・あらすじ概要\n" +
          "・公式リンク\n" +
          "\n" +
          "最新話をネタバレありで語りましょう！"
      },
      {
        value: "雑談2",
        text: "好きな◯◯について語ろう！",
        title: "好きな◯◯について語ろう",
        body: "「どこで一番泣いた/笑った？」\n" +
          "「最も好きなシーン/巻/章といえば？」\n" +
          "「好きなセリフ/名言/キャラ/小ネタは？」"
      }
    ]
  }

  const reviewMessages = () => {
    return [
      {
        value: "感想1",
        text: "◯巻の感想",
        title: "◯巻の感想・レビュー",
        body: "特定の巻数の感想を書くことができます。\n" +
          "\n" +
          "また、「◯巻〜◯巻まで」「◯◯編の感想」のようにまとめた感想を書くこともできます。"
      },
      {
        value: "感想2",
        text: "#推しを3行で推す",
        title: `${boardTitle} #推しを3行で推す`,
        body: "・読んだ直後に思ったこと　※一番大事！※\n" +
          "\n\n" +
          "・特に好きなところは？\n" +
          "\n\n" +
          "・作品の応援や未読の方へオススメする一言！\n"
      },
      {
        value: "感想3",
        text: "#1巻応援",
        title: `${boardTitle} #1巻応援`,
        body: "1巻まで発売されている作品（全1巻で完結を含む）を応援したいときに使います。\n" +
          "\n" +
          "「1巻発売されたばかりの作品をもっと世に広めたい…!」"
      },
      {
        value: "感想4",
        text: "#読切応援",
        title: `${boardTitle} #読切応援`,
        body: "雑誌に掲載された読切で連載化してほしいものや、漫画賞に入選した読切を世の中に知ってほしいときに使います。\n" +
          "\n" +
          "「この読切はぜひ連載化してほしい!!」"
      },
      {
        value: "感想5",
        text: "#完結応援",
        title: `${boardTitle} #完結応援`,
        body: "完結巻が発売されている作品にあらためてスポットライトを当てたい使います。（※完結した時期は問いません。）\n" +
          "\n" +
          "「完結を機にたくさんの人に読んでほしい」"
      }
    ]
  }

  let isPost = element => {
    return element.classList.contains("post");
  };

  let isPut = element => {
    return element.classList.contains("put");
  };

  let isAnonymousEnabled = element => {
    return element.querySelector("#topic_anonymous_enabled").checked;
  };

  let anonymousEnabledElement = element => {
    return element.querySelector("#topic_anonymous_enabled");
  };

  let getMessages = type => {
    let messages = [];

    const defaultMessage = {
      value: "guide",
      text: "💡 クチコミのヒントを表示する"
    }

    messages.push(defaultMessage);

    if (type === "question") {
      messages.push(...questionMessages());
    }

    if (type === "review") {
      messages.push(...reviewMessages());
    }

    if (type === "chat") {
      messages.push(...chatMessages());
    }

    return messages;
  }

  let getType = element => {
    let checkValue = '';

    document.getElementsByName('topic[type]').forEach(radioElement => {
      if (radioElement.checked) {
        checkValue = radioElement.value;
      }
    });
    return checkValue;
  }

  let updateGuideMessages = (element, guideType) => {
    const messages = getMessages(guideType);
    let guideTypeComboBoxElement = element.querySelector("#guide_type");

    while (guideTypeComboBoxElement.firstChild) {
      guideTypeComboBoxElement.removeChild(guideTypeComboBoxElement.firstChild);
    }

    messages.forEach(optionData => {
      const option = document.createElement('option');
      option.value = optionData.value;
      option.text = optionData.text;
      guideTypeComboBoxElement.appendChild(option);
    });
  }

  let initType = element => {
    const typeRadioButtonsElement = element.querySelector(".type-radio-buttons");
    updateGuideMessages(element, getType(element));

    typeRadioButtonsElement.addEventListener('change', function (event) {
      const selectedValue = event.target.value;
      updateGuideMessages(element, selectedValue);
    })
  }

  let initGuide = element => {
    const guideTypeComboBoxElement = element.querySelector("#guide_type");

    guideTypeComboBoxElement.addEventListener('change', function (event) {
      event.preventDefault();

      let topicTitleForm = document.querySelector("#topic_title");
      let commentBodyForm = document.querySelector("#comment_body");

      if (topicTitleForm.value !== "" || commentBodyForm.value !== "") {
        const result = confirm(
          "入力内容を消去して「クチコミのヒント」を表示しますか？"
        );
        if (result === false) {
          return;
        }
      }

      const selectedValue = event.target.value;
      const messages = getMessages(getType(element));

      const titleElement = element.querySelector("#topic_title");
      const bodyElement = element.querySelector("#comment_body");

      messages.forEach(message => {
        if (message.value === selectedValue) {
          titleElement.value = message.title;
          bodyElement.value = message.body;
        }
      })
    });
  }

  let initKeywordState = element => {
    let keywordsPartialElement = element.querySelector(".keywords-partial");
    let keywordsElement = keywordsPartialElement.querySelector(".keywords");
    let keywordElements = keywordsElement.querySelectorAll(".keyword");

    async function copyToClipboard(displayNameText, nameText, text) {
      let titleText = displayNameText == 'あらすじ' ? displayNameText : nameText

      try {
        await navigator.clipboard.writeText(nameText);
        window.alert(titleText + "をコピーしました")
      } catch (error) {
        window.alert("コピーを失敗しました")
      }
    }

    keywordElements.forEach(keywordElement => {
      keywordElement.addEventListener("click", function (e) {
        let displayNameElement = keywordElement.querySelector(".display-name");
        let displayNameText = displayNameElement.textContent
        let nameElement = keywordElement.querySelector(".name");
        let nameText = nameElement.textContent

        copyToClipboard(displayNameText, nameText,);
      });
    });
  }

  let initImageSelect = element => {
    const imageSelectorElement = element.querySelector("a#image-selector-button");
    const fileSelector = element.querySelector("input.image-field");
    const pasteImage = element.querySelector(".paste-image");

    imageSelectorElement.addEventListener("click", function (event) {
      event.preventDefault();

      fileSelector.addEventListener("change", function (e) {

        let files = e.target.files;

        if (files.length == 0) {
          let imageElement = pasteImage.querySelector("img");

          if (imageElement != null) {
            imageElement.remove();
            return;
          }

          return;
        }

        for (var i = 0, file; (file = files[i]); i++) {
          if (!file.type.match("image.*")) {
            continue;
          }

          var reader = new FileReader();

          reader.onload = (function (f) {
            return function (e) {
              try {
                let imageElement = pasteImage.querySelector("img");

                if (imageElement != null) {
                  imageElement.remove();
                  return;
                }

                var uploagImageElement = document.createElement("img");
                uploagImageElement.className = "upload-image";
                pasteImage.appendChild(uploagImageElement);

                var img = pasteImage.querySelector("img.upload-image");
                img.src = e.target.result;
              } catch (e) { }
            };
          })(file);

          reader.readAsDataURL(file);

          return;
        }
      });

      fileSelector.click();
    });
  }

  let initAnonymousEnabled = element => {
    const anonymousEnabledElement = element.querySelector("#topic_anonymous_enabled");
    const inputCommentElement = element.querySelector(".input-comment-name");
    const userIconImageElement = element.querySelector(".user-icon img");

    anonymousEnabledElement.addEventListener("click", function (e) {
      if (isAnonymousEnabled(element)) {
        inputCommentElement.style.display = "block";
        userIconImageElement.src = loginStateStore.getUserPrivateIconPath();
      } else {
        inputCommentElement.style.display = "none";
        userIconImageElement.src = loginStateStore.getUserIconPath();
      }
    });
  }

  let initPostButton = element => {
    let postButtonElement = element.querySelector("#post-button");
    postButtonElement.addEventListener("click", function (e) {
      let authenticityTokenElements = element.querySelectorAll(
        "input[name='authenticity_token'][type='hidden']"
      );
      authenticityTokenElements.forEach(element => {
        element.value = csrfTokenStoreInstance.get().csrfToken;
      });
      window.removeEventListener('beforeunload', beforeUnLoadEvent)
    });
  }

  let initForm = element => {
    const userIconImageElement = element.querySelector(".user-icon img");
    const inputCommentElement = element.querySelector(".input-comment-name");
    const inputElement = element.querySelector("#comment_name");
    const anonymousEnabledElement = element.querySelector("#topic_anonymous_enabled");

    if (loginStateStore.isLoggedIn()) {
      userIconImageElement.src = loginStateStore.getUserIconPath();

      if (isPost(element)) {
        inputCommentElement.style.display = "none";
        anonymousEnabledElement.checked = false;
      }

      if (isPut(element)) {
        if (isAnonymousEnabled(element)) {
          inputCommentElement.style.display = "block";
          userIconImageElement.src = loginStateStore.getUserPrivateIconPath();
        } else {
          inputElement.value = "名無し";
          inputCommentElement.style.display = "none";
        }
      }

      initAnonymousEnabled(element);
    } else {
      anonymousEnabledElement.checked = true;
      anonymousEnabledElement.style.pointerEvents = 'none';
      const label = element.querySelector('label[for="topic_anonymous_enabled"]');
      label.style.pointerEvents = 'none';
      anonymousEnabledElement.style.display = 'none';

    }

    initImageSelect(element);
    initPostButton(element);
  }

  let setResizeForm = element => {
    let commentBodyForm = element.querySelector("#comment_body");
    let clientHeight = commentBodyForm.clientHeight;

    commentBodyForm.addEventListener("input", () => {
      commentBodyForm.style.height = clientHeight + "px";
      let scrollHeight = commentBodyForm.scrollHeight;
      commentBodyForm.style.height = scrollHeight + "px";
    });
  };

  let beforeUnLoadEvent = (event) => {
    let topicTitleForm = document.querySelector("#topic_title");
    let topicTitle = topicTitleForm.value;

    let commentBodyForm = document.querySelector("#comment_body");
    let commentBody = commentBodyForm.value;

    if (commentBody.length > 0 || topicTitle.length > 0) {
      event.preventDefault();
      event.returnValue = "このページを離れますか？";
      return "このページを離れますか？";
    }
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initType(module);
    initGuide(module);
    initKeywordState(module);
    initForm(module);
    setResizeForm(module);
    window.addEventListener('beforeunload', beforeUnLoadEvent)
  });
});
