import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let modules = document.querySelectorAll(".light-review-board-action-icon-module");

  if (modules.length == 0) {
    return;
  }

  let overlayOpen = element => {
    const overlayElement = element.querySelector(".light-review-overlay");
    overlayElement.classList.add("on");

    const windowHeight = window.innerHeight;
    const top = (windowHeight / 2) - (overlayElement.getBoundingClientRect().height / 2)
    overlayElement.style.top = top + "px";
  }

  let initLightReviewAction = element => {
    const boardId = element.dataset.boardId
    const lightReviewAction = element.querySelector(".light-review-action");

    lightReviewAction.addEventListener("click", function (event) {
      event.stopPropagation();

      if (loginStateStore.isLoggedIn() == false) {
        const uri = encodeURI(location.pathname + location.search + location.hash);
        location.href = '/login?rt=' + uri;
        return;
      }

      let lightReviewModules = document.querySelectorAll(".light-review-overlay-module");

      if (lightReviewModules.length == 0) {
        return;
      }

      lightReviewModules.forEach(lightReviewModule => {
        const lightReviewBoardId = lightReviewModule.dataset.boardId;
        if (boardId == lightReviewBoardId) {
          overlayOpen(lightReviewModule);
        }
      });
    });
  };

  let initState = () => {
    modules.forEach(element => {
      initLightReviewAction(element);
    });
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

  pubsubStore.subscribe("infiniteScrollPageAppend", appentRootElement => {
    appentRootElement.querySelectorAll(".light-review-board-action-icon-module").forEach(element => {
      initLightReviewAction(element);
    });
  });

});
