import Api from "../../Api";
import pubsubStore from "../../pubsub_store";
import loginStateStore from "../../login_state_store";

document.addEventListener("DOMContentLoaded", function (event) {
  let topicElement = document.querySelector("#owned-topic");
  if (topicElement === null) {
    return;
  }
  let initState = () => {
    Api.userTopicOwned(topicElement.dataset.ownedTopicId).then(json => {
      let targetElement = document.querySelector("#owned-topic");
      if (json.owned == true) {
        targetElement.style.display = "block";
      } else {
        targetElement.remove();
      }
    });
  };
  pubsubStore.subscribe("userLoginCompleted", args => {
    if (loginStateStore.isLoggedIn()) {
      initState();
    }
  });
});
