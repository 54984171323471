document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  let isExist = document.getElementsByClassName("manba-magazines-pickup-author")
    .length;

  if (isExist == false) {
    return;
  }

  let modules = document.querySelectorAll(".manba-magazines-pickup-author");
  modules.forEach(element => {
    let description = element.getElementsByClassName("description")[0];
    let readMore = element.getElementsByClassName("description-readmore")[0];

    readMore.addEventListener("click", function() {
      description.style.height = "auto";
      description.style.overflow = "none";
      readMore.style.display = "none";
    });
  });
});
