document.addEventListener("DOMContentLoaded", function (event) {
  let isExist = document.getElementById("js-layout-boards");

  if (isExist == null) {
    return;
  }

  let isNowSmarthone = undefined;


  function updateResize() {
    let isSmartPhone = app.isSmartPhone();
    if (isNowSmarthone == isSmartPhone) {
      return;
    }

    updateBoardInfoPosition();
    updateActionsPosition();
    updateStoresPosition();

    isNowSmarthone = isSmartPhone;

  }

  function updateBoardInfoPosition() {
    let desktopBoardInfoElement = document.querySelector("#desktop-board-info");
    let smartphoneBoardInfoElement = document.querySelector("#smartphone-board-info")
    let boardInfoElement = document.querySelector(".board-info-components");

    if (
      desktopBoardInfoElement == null ||
      smartphoneBoardInfoElement == null ||
      boardInfoElement == null
    ) {
      return;
    }

    if (app.isSmartPhone()) {
      smartphoneBoardInfoElement.appendChild(boardInfoElement);
    } else {
      desktopBoardInfoElement.appendChild(boardInfoElement);
    }
  }

  function updateActionsPosition() {
    let desktopPostionElement = document.querySelector(".board-actions-desktop-partial")
    let smartphonePostionElement = document.querySelector(".board-actions-smartphone-partial")
    let actionsElement = document.querySelector(".board-actions-components");

    if (
      desktopPostionElement == null ||
      smartphonePostionElement == null ||
      actionsElement == null
    ) {
      return;
    }

    if (app.isSmartPhone()) {
      smartphonePostionElement.appendChild(actionsElement);
    } else {
      desktopPostionElement.appendChild(actionsElement);
    }

  }

  function updateStoresPosition() {
    let desktopPostionElement = document.querySelector(".board-stores-desktop-partial")
    let smartphonePostionElement = document.querySelector(".board-stores-smartphone-partial")
    let storesElement = document.querySelector(".board-stores-module");

    if (
      desktopPostionElement == null ||
      smartphonePostionElement == null ||
      storesElement == null
    ) {
      return;
    }

    if (app.isSmartPhone()) {
      smartphonePostionElement.appendChild(storesElement);
    } else {
      desktopPostionElement.appendChild(storesElement);
    }

  }

  window.addEventListener(
    "resize",
    function () {
      updateResize();
    },
    { passive: true }
  );

  updateResize();
});
