import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function(event) {
    "use strict";
    let modules = document.querySelectorAll(".image-with-expansion-module");

    if (modules.length == 0) {
        return;
    }

    let initImageBanner = element => {
        let imageBannerElement = element.querySelector(".image-banner");
        let clipArtElement = element.querySelector(".clip-art");

        if (imageBannerElement === null ){
            return
        }

        imageBannerElement.addEventListener("click", function() {
            imageBannerElement.remove();
            clipArtElement.classList.remove("hide");
        });
    }

    let initState = () => {
        modules.forEach(element => {
            initImageBanner(element)
        });
    };

    initState();

    pubsubStore.subscribe("infiniteScrollPageAppend", appendRootElement => {
        appendRootElement.querySelectorAll(".image-with-expansion-module").forEach(element => {
            initImageBanner(element)
        });
    });
});
