import Api from "../../Api";

document.addEventListener("DOMContentLoaded", function(event) {
  let element = document.getElementById("author-view-counter");

  if (element == null) {
    return;
  }
  let authorId = element.dataset.authorId;
  Api.authorViewCounter(authorId).then(json => {});
});
