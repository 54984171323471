document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let topModuleElement = document.querySelector(".profiles-user-module");
  if (topModuleElement == null) {
    return;
  }

  let desciptionElement = document.querySelector(".user-profile-desciption");
  if (desciptionElement == null) {
    return;
  }

  let desciptionReadmoreElements = document.querySelectorAll(".profile-desciption-readmore");
  if (desciptionReadmoreElements == null) {
    return;
  }

  desciptionReadmoreElements.forEach(element => {
    element.addEventListener("click", function () {
      desciptionElement.style.maxHeight = "none";
      desciptionElement.style.overflow = "none";
      element.style.display = "none";
    });
  });
});
