document.addEventListener("DOMContentLoaded", function (event) {

  let searchAction = (editElement, actiomElement) => {
    actiomElement.addEventListener("click", function () {
      let value = editElement.value;

      const baseSearchPath = "/filter_search?q=";
      let redirectPath;

      if (value !== "") {
        redirectPath = baseSearchPath + value;
      } else {
        redirectPath = baseSearchPath;
      }

      location.href = redirectPath;
    });
  }

  let desktopSearchAction = () => {
    const desktopFilterSearchEditElement = document.querySelector("#desktop-header-search-autocomplete-input");
    const desktopFilterSearchActionElement = document.querySelector(".desktop-header-advanced-search");

    searchAction(desktopFilterSearchEditElement, desktopFilterSearchActionElement)
  }

  let smartphoneSearchAction = () => {
    const smartphoneFilterSearchEditElement = document.querySelector("#smartphone-header-search-autocomplete-input");
    const smartphoneFilterSearchActionElement = document.querySelector(".smartphone-header-search .button");

    searchAction(smartphoneFilterSearchEditElement, smartphoneFilterSearchActionElement)
  }

  desktopSearchAction();
  smartphoneSearchAction();

});
